
import { KeyenceModule, ScanResult, isKeyenceEnterpriseBrowser } from '@/utils/keyenceHT/keyenceModules'
import { Options, Vue } from 'vue-class-component'
import { QRCODE_BARCODE_TYPE_REGEX } from '@/utils/constants'
import IconScanQR from '../svg/IconScanQR.vue'
import { PropSync } from 'vue-property-decorator'

@Options({
  components: {
    IconScanQR,
  },
})
export default class ScanButton extends Vue {
  @PropSync('loading', { type: Boolean }) _loading!: boolean

  get isEnterpriseBrowser(): boolean {
    return isKeyenceEnterpriseBrowser()
  }

  async mounted() {
    if (KeyenceModule && KeyenceModule.Scanner) {
      KeyenceModule.Scanner.clearReadCallback()
      KeyenceModule.Scanner.setReadCallback('keyenceHandyTerminalScanResultCallback')
    }

    window.keyenceHandyTerminalScanResultCallback = (result: ScanResult) => {
      if (result.mDecodeResult !== 'SUCCESS') {
        return
      }
      const regexBarcodeId = QRCODE_BARCODE_TYPE_REGEX
      if (result.mStringData.match(regexBarcodeId)) {
        const urlParts = result.mStringData.split('/')
        const barcodeId = urlParts[urlParts.length - 1].trim()
        const projectId = urlParts[urlParts.length - 3].trim()
        this._loading = true
        this.$router.push({
          name: 'detail',
          params: {
            barcodeId: barcodeId,
            project: projectId,
          },
        })
      } else {
        const detectedText = result.mStringData?.trim() || ''
        const projectCode = this.$route?.params?.project.split('@')
        if (projectCode.length > 1) projectCode.pop()

        this._loading = true
        this.$router.push({
          name: 'detail',
          params: { barcodeId: `projectCode=${projectCode[0]}` },
          query: { externalId: detectedText },
        })
      }
    }
  }

  async startScan() {
    KeyenceModule.Scanner.startRead()
  }
}
