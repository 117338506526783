import { axiosInstance } from '@/utils/api'
import { LOCAL_STORAGE_ITEM } from '@/utils/constants'
import { getVisitorId } from '@/utils/helpers'
import { IRegisterInfo, LoginPayload } from 'smartbarcode-web-core/src/utils/types/index'

export async function login(payload: LoginPayload) {
  payload.visitorId = await getVisitorId()
  await axiosInstance.post('/user/authorize', payload)
}

export async function logout() {
  const payload = { visitorId: await getVisitorId() }
  localStorage.removeItem(LOCAL_STORAGE_ITEM.VISITOR_ID)
  await axiosInstance.post('/user/logout', payload)
}

export async function getProfileResponse() {
  const response = await axiosInstance.get('/user/profile')
  return response
}

export async function getProfile() {
  return (await getProfileResponse())?.data
}

export async function getAddressBook() {
  const response = await axiosInstance.get('/user/address')
  return response?.data
}

export async function register(registerInfo: IRegisterInfo) {
  registerInfo.visitorId = await getVisitorId()
  const response = await axiosInstance.post('/user/carrier-user/self', registerInfo)
  return response
}
