
import { getChildrenValue, uploadImage } from '@/utils/api'
import { DATE_TIME, INPUT_COMPONENTS } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { dataURLtoFile, deepSearch } from '@/utils/helpers'
import { format } from 'date-fns'
import { UploadFile } from 'element-plus/lib/el-upload/src/upload.type'
import cloneDeep from 'lodash/cloneDeep'
import { maskPhoneNum } from 'smartbarcode-web-core/src/utils/barcodeHelper'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import getGhgEmissionMap from 'smartbarcode-web-core/src/utils/ghgEmission/calc'
import { GhgEmissionCalculationMaterial } from 'smartbarcode-web-core/src/utils/ghgEmission/GhgEmissionCalculationMaterial'
import { GhgEmissionCustomFieldConclusion } from 'smartbarcode-web-core/src/utils/ghgEmission/GhgEmissionCustomFieldConclusion'
import { GhgEmissionInput } from 'smartbarcode-web-core/src/utils/ghgEmission/GhgEmissionInput'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IActivationData,
  ICustomFormFields,
  IFieldSettings,
  IFormInputSchema,
  TFieldNotiSettings,
  TFormSchema,
  TOptions,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
  name: 'CustomFieldsMixin',
})
export default class CustomFieldsMixin extends Vue {
  formModel: Record<string, unknown> = {}

  // Return the raws files if any file uploades
  fileListFormModel: Record<string, UploadFile[]> = {}

  /**
   * Override this method for insert data
   */
  get customForm(): Record<string, IFieldSettings | TFieldNotiSettings> {
    return {}
  }

  /**
   * Flatten fields of form schema
   */
  get flattenFields(): Record<string, IFieldSettings> {
    const fields = this.customForm
    const flatten = Object.keys(fields).reduce((acc, cur) => {
      // exclude nextTrackPointNotification now
      if (cur === 'nextTrackPointNotification') return acc

      if (cur === ECustomFieldType.LOCATION) {
        return {
          ...acc,
          [cur]: {
            order: 0, // Hardcode order of location data to the top
            fieldType: ECustomFieldType.LOCATION,
            ...fields[cur],
          },
        }
      }
      return { ...acc, ...fields[cur] }
    }, {})
    return flatten
  }

  /**
   * Select form schema
   */
  get selectedFormSchema() {
    const sortedFields = Object.entries(this.flattenFields)
      .sort((a, b) => {
        const orderA = a[1].order ?? 999
        const orderB = b[1].order ?? 999
        return orderA - orderB
      })
      .filter((f) => {
        // Only fieldType is Location need to be check on `isAvailable` fields.
        // This is inconsistent of field schema
        if (f?.[1]?.fieldType === ECustomFieldType.LOCATION) {
          return f[1].isAvailable
        }
        return !f[1].isHidden
      })

    const customFormSchema: TFormSchema = {}
    sortedFields.forEach(([fieldName, inputSchema]) => {
      const { fieldType, multiLine, maxLength, minLength, countryCode, ...others } = inputSchema

      customFormSchema[fieldName] = {
        fieldType,
        ...(fieldType && !!INPUT_COMPONENTS[fieldType] && { component: INPUT_COMPONENTS[fieldType] }),
        ...(maxLength && { maxLength }),
        ...(minLength && { minLength }),
        ...(countryCode && { countryCode }),
        ...(multiLine && { multiLine }),
        ...(fieldType === ECustomFieldType.ESIGN && { disableError: true }),
        ...others,
      }
    })
    return customFormSchema
  }

  /**
   * Generate Custom Data for request
   */
  get generateCustomDataForRequest(): Record<string, unknown> | ICustomFormFields {
    const copy: Record<string, unknown> = { ...this.formModel }
    Object.entries(copy).forEach(([key, value]) => {
      if (value instanceof Date) {
        copy[key] = format(value, DATE_TIME.WITHOUT_SECOND)
      }
      // if value is Object and have default value
      if (value instanceof Object) {
        copy[key] = (copy[key] as IFormInputSchema)?.default || copy[key]
      }
    })
    const data = Object.keys(copy).reduce((acc, curr) => {
      if (this.selectedFormSchema[curr]) {
        const fieldType = this.selectedFormSchema[curr].fieldType
        if (fieldType === ECustomFieldType.TEXT) {
          return { ...acc, [curr]: { fieldType, text: copy[curr] } }
        }
        if (fieldType === ECustomFieldType.PHONE_NUMBER) {
          return {
            ...acc,
            [curr]: {
              fieldType,
              phoneNumber: {
                countryCode: this.selectedFormSchema[curr].countryCode,
                number: (copy[curr] as string).replaceAll('-', ''),
              },
            },
          }
        }
        if (fieldType === ECustomFieldType.EMAIL && !isEmpty(copy[curr])) {
          return { ...acc, [curr]: { fieldType, email: copy[curr] } }
        }
        if (fieldType === ECustomFieldType.NUMBER) {
          return { ...acc, [curr]: { fieldType, number: copy[curr] } }
        }
        if (fieldType === ECustomFieldType.SINGLE_SELECT && !isEmpty(copy[curr])) {
          const selections = (this.selectedFormSchema?.[curr]?.selections as unknown) as Record<string, TOptions>
          const singleSelect = {
            key: copy[curr],
            value: selections[copy[curr] as string].label,
          }
          return { ...acc, [curr]: { fieldType, singleSelect } }
        }
        if (fieldType === ECustomFieldType.MULTI_SELECT && !isEmpty(copy[curr])) {
          const selections = (this.selectedFormSchema?.[curr]?.selections as unknown) as Record<string, TOptions>

          const multiSelect = (copy[curr] as Array<unknown>)
            .filter((k) => k !== '')
            .map((val) => ({
              key: val,
              value: selections[val as string].label,
            }))

          return { ...acc, [curr]: { fieldType, multiSelect } }
        }
        if (fieldType === ECustomFieldType.DATE) {
          const date = copy[curr]
          const field = { fieldType, date }
          if (!date) return { ...acc }
          return { ...acc, [curr]: field }
        }
        if (fieldType === ECustomFieldType.ESIGN) {
          const eSign = copy[curr]
          if (!eSign) return { ...acc }
          return { ...acc, [curr]: { fieldType, eSign } }
        }
        if (fieldType === ECustomFieldType.FILES) {
          const files = (copy[curr] as Array<unknown>).map((val) => ({ path: val }))
          return { ...acc, [curr]: { fieldType, files } }
        }
        if (fieldType === ECustomFieldType.LOCATION) {
          return { ...acc, [curr]: copy[curr] }
        }
        if (fieldType === ECustomFieldType.REFERENCE) {
          const reference = copy[curr] as IFormInputSchema
          return {
            ...acc,
            [curr]: {
              fieldType,
              reference: {
                template: reference.template,
              },
            },
          }
        }

        if (fieldType === ECustomFieldType.GHG_EMISSION) {
          const form = copy[curr] as GhgEmissionInput
          const calculationType = this.selectedFormSchema[curr].calculationType
          const material = {
            calculationType: calculationType,
            gasTypes: ['CO_2'], // for now, we use only CO_2
            transportType: form.transportType,
            weight: form.weight,
            distance: form.distance,
            maxWeightCapacity: form.maxWeightCapacity,
            loadRate: form.loadRate,
            vehicleUsageType: form.vehicleUsageType,
            fuelConsumption: form.fuelConsumption,
          } as GhgEmissionCalculationMaterial

          const emissionMap = getGhgEmissionMap(material)
          const ghgEmission = {
            fieldType: fieldType,
            [ECustomFieldType.GHG_EMISSION]: emissionMap.get('CO_2'), // for now, we use only CO_2 value
            material: material,
          } as GhgEmissionCustomFieldConclusion
          return {
            ...acc,
            [curr]: ghgEmission,
          }
        }
      }
      return acc
    }, {})

    return data
  }

  /**
   * Generate default form model for Form Schema
   */
  generateCustomFormModel(initData?: IActivationData) {
    const textFieldtypes = new Set([ECustomFieldType.DATE, ECustomFieldType.TEXT, ECustomFieldType.EMAIL])
    Object.entries(this.selectedFormSchema).forEach(async ([key, settings]) => {
      let result
      if (initData) {
        deepSearch(initData?.customFields || {}, key, (k: string, v: string | number | string[]) => (result = v))
      }

      // if copyFromPairedBarcode is true getting from API and set default value
      if (this.selectedFormSchema[key]?.copyFromPairedBarcode) {
        const res = await getChildrenValue(this.$route.params.barcodeId, key).catch(() => false)
        if (res) {
          this.selectedFormSchema[key].default = res
        }
      }
      const fieldType = settings?.fieldType || ''
      if (fieldType === ECustomFieldType.MULTI_SELECT) {
        const value: Array<string> = []
        if (result) {
          const multipleSelect = (result as IFormInputSchema).multiSelect
          if (multipleSelect) {
            multipleSelect.forEach((val) => value.push(val.key))
          }
        } else {
          Object.entries(settings.selections as TOptions).forEach(([optionValue, optionSetting]) => {
            if (optionSetting?.default) {
              value.push(optionValue)
            }
          })
        }
        this.formModel[key] = value
      }
      if (fieldType === ECustomFieldType.SINGLE_SELECT) {
        const selectedOption = Object.entries(settings.selections as TOptions).find(
          (radioSetting) => radioSetting[1]?.default
        )
        this.formModel[key] = ((result as unknown) as IFormInputSchema)?.singleSelect?.key || selectedOption?.[0]
      }
      if (fieldType === ECustomFieldType.NUMBER) {
        if (result) {
          if (typeof result !== 'object') {
            this.formModel[key] = result
          }
          if (typeof result === 'object') {
            this.formModel[key] = (result as IFormInputSchema)?.number || this.selectedFormSchema[key]?.default
          }
        }
      }
      if (fieldType === ECustomFieldType.ESIGN) {
        this.formModel[key] = result?.[ECustomFieldType.ESIGN]
      }
      if (fieldType === ECustomFieldType.REFERENCE) {
        this.formModel[key] = cloneDeep(result?.[ECustomFieldType.REFERENCE]) || settings
      }

      if (fieldType && textFieldtypes.has(fieldType as ECustomFieldType)) {
        const type = typeof result
        if (result) {
          if (type === 'object') {
            this.formModel[key] =
              (result as IFormInputSchema).text ||
              (result as IFormInputSchema).date ||
              (result as IFormInputSchema).email ||
              this.selectedFormSchema[key]?.default ||
              ''
          }
          return ''
        }

        this.formModel[key] = ''
      }

      if (fieldType === ECustomFieldType.PHONE_NUMBER) {
        if (result && (result as IFormInputSchema).phoneNumber) {
          const r = result as IFormInputSchema
          this.formModel[key] = maskPhoneNum(r.phoneNumber?.number ?? '', r.phoneNumber?.countryCode ?? 'JP')
        }
      }
      if (fieldType === ECustomFieldType.FILES) {
        if (result) {
          this.formModel[key] = (result as IFormInputSchema).files
        }
      }
    })
  }

  async saveOCRImage(event: Record<string, string>) {
    try {
      this.loading = true
      const flattenFields = cloneDeep(this.flattenFields) || {}
      const basicActivationField: Record<string, string> = {
        trackingNumber: this.isAutoSaveOcrImageTrackingNumber || '',
        externalId: this.isAutoSaveOcrImageExternalId || '',
      }
      const keys = Object.keys(event)
      for (const index in keys) {
        const key = keys[index]
        if (isEmpty(key)) return
        const ocrImageAutoSaveFieldKey =
          flattenFields?.[key]?.ocrImageAutoSaveField || basicActivationField?.[key] || ''
        if (ocrImageAutoSaveFieldKey) {
          if (this.temporaryFieldsOCRImages?.[key]?.[ocrImageAutoSaveFieldKey]) {
            ;(this.temporaryFieldsOCRImages?.[key]?.[ocrImageAutoSaveFieldKey]).forEach(
              (val: string, index: number) => {
                const found = this.imagesFromOCR?.[ocrImageAutoSaveFieldKey].find((el: string, idx: number) => {
                  if (el === val) {
                    ;(this.imagesFromOCR?.[ocrImageAutoSaveFieldKey] as string[]).splice(idx, 1)
                  }
                  return el === val
                })
                if (found) {
                  ;(this.temporaryFieldsOCRImages?.[key]?.[ocrImageAutoSaveFieldKey] as string[]).splice(index, 1)
                }
              }
            )
          }

          const file = dataURLtoFile(event[key])
          const formData = new FormData()
          if (!file) return
          // moment()
          formData.append('barcodeId', this.barcode?.id)
          const extension = this.getFileExtension(file)
          formData.append('file', file, `OCR_${ocrImageAutoSaveFieldKey}.${extension}`)
          const image = await uploadImage(formData).catch((e) => errorHandler(e))
          event[key] = image
          if (this.imagesFromOCR?.[ocrImageAutoSaveFieldKey]) {
            // eslint-disable-next-line no-unused-expressions
            this.imagesFromOCR?.[ocrImageAutoSaveFieldKey].push(image)
          } else {
            this.imagesFromOCR = { ...this.imagesFromOCR, ...{ [ocrImageAutoSaveFieldKey]: [image] } }
          }

          // Save images to temporary fields to compare delete images when re-ocr of fields ↑
          if (this.temporaryFieldsOCRImages?.[key]?.[ocrImageAutoSaveFieldKey]) {
            // eslint-disable-next-line no-unused-expressions
            this.temporaryFieldsOCRImages?.[key]?.[ocrImageAutoSaveFieldKey]?.push(image)
          } else {
            this.temporaryFieldsOCRImages = {
              ...this.temporaryFieldsOCRImages,
              ...{
                [key]: {
                  [ocrImageAutoSaveFieldKey]: [image],
                },
              },
            }
          }

          URL.revokeObjectURL(event[key])
        }
      }
    } catch (error) {
    } finally {
      this.loading = false
    }
  }

  getFileExtension(file: Blob) {
    if (!file) return
    const splitedType = file.type.split('/')
    return splitedType[splitedType.length - 1]
  }
}
