
import FormBuilder from '@/components/FormBuilder.vue'
import InputSelectCountry from '@/components/InputSelectCountry.vue'
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'
import { DEFAULT_ADDRESS } from '@/utils/constants'
import { observerClean } from '@/utils/helpers'
import omit from 'lodash/omit'
import { OTHER_COUNTRY_LIST, PHONE_MASK, ZIPCODE_MASK } from 'smartbarcode-web-core/src/utils/constants'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums'
import { isObjectValueEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IAddress, TFormSchema } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import InputPhoneNumber from './InputPhoneNumber.vue'
@Options({
  components: {
    FormBuilder,
  },
  emits: ['submit'],
  name: 'ShippingInfo',
})
export default class ShippingInfo extends mixins(BarcodeTypesMixin) {
  @Prop({ type: String }) readonly barcodeType: string = ''
  @Prop({ type: Object }) readonly address: IAddress | null = null
  @Prop({ type: Boolean }) readonly hasNextStep?: boolean
  @Prop({ type: Boolean }) readonly isMandatory?: boolean

  formSchema: TFormSchema = {}
  formModel: IAddress = { ...DEFAULT_ADDRESS }
  error = Object.keys(this.formModel).map((f) => ({ [f]: false }))
  selectedCountry = ''

  get countriesList() {
    return this.getBarcodeTypeInfoByType(this.barcodeType)?.countries || []
  }

  get isClientUser() {
    return this.$store.getters.isClientUser
  }

  get isCountryInputDisabled() {
    return this.getCountriestList?.length === 1
  }

  get getCountriestList() {
    return this.$store.state?.project?.details?.barcodeTypes[this.barcodeType]?.countries
  }

  @Emit('submit')
  onSubmit(formData: IAddress): IAddress {
    // remove country in formData in case: 1 country in options & no other field's filled
    const isRemoveCountry = isObjectValueEmpty(omit(formData, 'country')) && this.isCountryInputDisabled
    if (isRemoveCountry) formData.country = ''

    return formData
  }

  formRemask(country: string) {
    const phoneMask = PHONE_MASK[country] || ''
    const zipCodeMask = ZIPCODE_MASK[country] || ''

    this.formSchema.phoneNumber = {
      ...this.formSchema.phoneNumber,
      ...{
        placeholder: phoneMask.replaceAll('#', '_'),
        mask: phoneMask,
      },
    }
    this.formSchema.postalCode = {
      ...this.formSchema.postalCode,
      ...{
        placeholder: zipCodeMask.replaceAll('#', '_'),
        mask: zipCodeMask,
      },
    }
  }

  shouldSkipValidate() {
    // Need validation if mandatory
    if (this.isMandatory) return false

    return isObjectValueEmpty(omit(this.formModel, 'country'))
  }

  created() {
    if (this.address) this.formModel = observerClean(this.address)

    this.formSchema = {
      companyName: {
        label: this.$t('company_name'),
        isMandatory: true,
      },
      country: {
        label: this.$t('country'),
        component: InputSelectCountry,
        barcodeType: this.barcodeType,
        width: 12,
        disabled: this.isCountryInputDisabled,
        isMandatory: true,
        placeholder: this.$t('country'),
      },
      postalCode: {
        label: this.$t('postal_code'),
        width: 12,
        isMandatory: true,
        inputType: 'tel',
      },
      prefecture: {
        label: this.$t('prefecture'),
        isMandatory: true,
      },
      address1: {
        label: this.$t('address1'),
        isMandatory: true,
      },
      address2: {
        label: this.$t('address2'),
      },
      phoneNumber: {
        label: this.$t('phone_number'),
        isMandatory: true,
        component: InputPhoneNumber,
        inputType: ECustomFieldType.PHONE_NUMBER,
        isSuggestion: false,
        // inputType: 'tel',
      },
    }
    this.setDefaultSelectedCountry()
  }

  @Watch('formModel.country')
  onCountryChange(country: string) {
    if (!country) return

    this.selectedCountry = country
    this.formRemask(country)
  }

  @Watch('address')
  onAddressChange(newAddress?: IAddress) {
    this.formModel = newAddress ?? this.defaultAddress
    this.setDefaultSelectedCountry()
  }

  private setDefaultSelectedCountry() {
    if (this.isCountryInputDisabled) {
      this.formModel.country = this.selectedCountry || this.countriesList[0]
    } else if (this.selectedCountry) {
      this.formModel.country = this.selectedCountry
    }
    this.formRemask(this.formModel.country)
  }
}
