
import BarcodeSearchButton from '@/components/common/BarcodeSearchButton.vue'
import DigitCountButton from '@/components/common/DigitCountButton.vue'
import CompanyName from '@/components/CompanyName.vue'
import KeyenceScanButton from '@/components/keyenceHT/ScanButton.vue'
import KeyenceScannerConfig from '@/components/keyenceHT/ScannerConfig.vue'
import IconHelp from '@/components/svg/IconHelp.vue'
import IconLogout from '@/components/svg/IconLogout.vue'
import IconQRcode from '@/components/svg/IconQRcode.vue'
import IconScanQR from '@/components/svg/IconScanQR.vue'
import { CLEAR_BARCODE, FETCH_PROFILE, FETCH_PROJECT } from '@/store/actions'
import { generateBarcode, login } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/helpers'
import { isKeyenceEnterpriseBrowser } from '@/utils/keyenceHT/keyenceModules'
import { maska } from 'maska'
import { LoginPayload, TError } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    IconQRcode,
    IconScanQR,
    IconLogout,
    CompanyName,
    DigitCountButton,
    BarcodeSearchButton,
    KeyenceScanButton,
    KeyenceScannerConfig,
    IconHelp,
  },
  directives: { maska },
  name: 'Home',
})
export default class Home extends Vue {
  loading = false
  firstCode = ''
  lastCode = ''
  password = ''
  otpCode = ''
  isDisableCodeInput = true
  error = ''

  created() {
    this.$store.dispatch(CLEAR_BARCODE)
  }

  resetField() {
    this.loading = false
    this.firstCode = ''
    this.lastCode = ''
    this.password = ''
    this.otpCode = ''
    this.isDisableCodeInput = true
    this.error = ''
  }

  get image() {
    return this.$store.getters.customLogoImageUrl || require('@/assets/logo-smartBarcode.png')
  }

  get isAuth(): boolean {
    return this.$store.state.profile.user !== null
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }

  get allowGenerateNewBarcode() {
    return this.$store.getters.projectPermission?.generateBarcodePermission?.canEdit || this.isClientUser || false
  }

  get projectDetail() {
    return this.$store.state.project.details
  }

  get showBarcodeSearchPage() {
    return this.projectDetail.showBarcodeSearchPage
  }

  get showTrackingCountPage() {
    return this.projectDetail.showTrackingCountPage
  }

  get isRequirePassword(): boolean {
    return this.projectDetail?.requirePassword
  }

  get isRequireOTP(): boolean {
    return this.projectDetail?.requireOneTimePasscode
  }

  get isAllowUserSelfRegister(): boolean {
    return this.projectDetail?.allowUserSelfRegister
  }

  get loginPayload(): LoginPayload {
    return {
      projectId: this.projectDetail?.id,
      accessCode: `${this.firstCode}${this.lastCode}`,
      password: this.password,
    }
  }

  get nextRouteName() {
    return this.$route?.query?.name ?? ''
  }

  get nextRouteParams() {
    return this.$route.query?.id ?? ''
  }

  get isClientUser(): boolean {
    return this.$store.getters.isClientUser
  }

  get isKeyenceEnterpriseBrowser() {
    return isKeyenceEnterpriseBrowser()
  }

  saveLoginData() {
    this.$store.dispatch(FETCH_PROFILE)
    if (this.nextRouteName && this.nextRouteParams) {
      this.$router.push({
        name: this.nextRouteName,
        params: {
          barcodeId: this.nextRouteParams,
        },
      })
    }
    window.scroll(0, 0)
  }

  async start() {
    try {
      this.loading = true

      const loginPayload = this.loginPayload
      if (!this.isDisableCodeInput) {
        loginPayload.oneTimePasscode = this.otpCode
      }
      await login(this.loginPayload)
      this.resetField()
      this.saveLoginData()
      await this.$store.dispatch(FETCH_PROJECT, this.projectParam)
    } catch (error) {
      if (error) {
        if (typeof error === 'object' && 'oneTimePasscode' in error && typeof error.oneTimePasscode === 'string') {
          if (error?.oneTimePasscode?.includes('1000')) {
            this.isDisableCodeInput = false
            this.error = this.$t('account.confirm_code_already_sent')
            return
          }
          if (error?.oneTimePasscode?.includes('2002')) {
            this.isDisableCodeInput = false
            this.error = this.$t('account.incorrect_otp_passcode')
            return
          }
        }

        switch (error) {
          case '3057':
            openMessage(this.$t('account.confirm_code_sent'), 'success')
            this.isDisableCodeInput = false
            return
          default:
            break
        }
      }

      this.error = this.$t('invalid password')
    } finally {
      this.loading = false
    }
  }

  onCodeInput(value: string) {
    if (value.length === 4) {
      this.$refs.code.focus()
    }
  }

  async onClickGenerate() {
    try {
      this.loading = true
      const result = await generateBarcode(this.$store.getters.projectCode, `${this.$store.getters.projectVersion}`)
      this.$router.push({
        name: 'detail',
        params: { project: this.projectParam, barcodeId: result.id },
      })
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.loading = false
    }
  }
}
