
import RecycleHistoryItem from '@/components/RecycleHistoryItem.vue'
import { FETCH_BARCODE } from '@/store/actions'
import { getRecycleHistory } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { IBarcode, TError } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {
    RecycleHistoryItem,
  },
  name: 'RecycleHistory',
})
export default class RecycleHistory extends Vue {
  loading = true
  histories: IBarcode[] = []
  currentPageNum = 1
  currentSkip = 0
  totalBarcodes = 0

  get project() {
    return this.$store.state.project.details
  }

  get barcode() {
    return this.$store.state.barcode.barcode
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }

  mounted() {
    // Update barcode data in store if barcodeId is different from parameter.
    // This happens when browser history is used to move from another barcode detail page.
    if (this.barcode.id === this.$route.params.barcodeId) {
      this.loadHistory()
    } else {
      this.reloadBarcode()
    }
  }

  async loadHistory() {
    try {
      this.histories = await getRecycleHistory(this.barcode.id, this.currentSkip, 10)
      this.loading = false
    } catch (ex) { }
  }

  async currentPageChange(pageNum: number) {
    try {
      this.loading = true
      this.currentPageNum = pageNum
      const skipNum = (Number(pageNum) - 1) * Number(10)
      this.currentSkip = skipNum
      await this.loadHistory()
    } catch (e) {
      errorHandler(e as TError)
    } finally {
      this.loading = false
    }
  }

  get getBarcodePerPageSize() {
    return Math.round((window.innerHeight - 100) / 100)
  }

  get totalRecycleCount() {
    return this.barcode.recycleCount || 0
  }

  reloadBarcode() {
    this.$store.dispatch(FETCH_BARCODE, { id: this.$route.params.barcodeId })
  }

  @Watch('barcode')
  onBarcodeUpdate() {
    this.loadHistory()
  }
}
