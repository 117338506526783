<template>
  <div v-loading.fullscreen="loading" v-show="!loading" class="scan-view">
    <div class="barcode-scanner">
      <div class="scan-area" v-if="isScanditReader" id="scan-area"></div>
      <div id="scan-area" class="scan-area" v-else>
        <video id="video"></video>
      </div>
      <BarcodeSymbologiesSelection class="absolute bottom-2 z-50 left-2 flex w-52"
        @update:onSymbologiesChange="setBarcodeSymbologies" v-model:symbologies="symbologies" />
    </div>

    <div>
      <div :class="['dectect-input', isScanditReader ? 'margin-ocr' : 'margin-top-reader']">
        <el-input class="text-center text-lg" v-model="detectedText">
          <template #prepend>
            <i class="el-icon-view"></i>
          </template>
        </el-input>
      </div>
      <div class="step-button">
        <el-button :disabled="!detectedText" circle type="primary" class="submit-button" @click="confirm">
          OK
        </el-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { promptVideoPermission } from '@/utils/helpers'
import BarcodeSymbologiesSelection from '@/components/BarcodeSymbologiesSelection.vue'
import 'cropperjs/dist/cropper.css'
import { BrowserMultiFormatReader, Result } from '@zxing/library'
import { Prop } from 'vue-property-decorator'
import { BARCODE_SCAN } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { TError } from 'smartbarcode-web-core/src/utils/types/index'
import BarcodeReaderMixin from '@/components/mixins/BarcodeReaderMixin.vue'
import { EBCRType } from 'smartbarcode-web-core/src/utils/enums/index'
import { BarcodeCapture, Barcode } from 'scandit-web-datacapture-barcode'

// Mixins
@Options({
  components: {
    BarcodeSymbologiesSelection,
  },
  emits: ['update:modelValue', 'update:confirm'],
  name: 'ScanComponent',
})
export default class OCRComponent extends mixins(BarcodeReaderMixin) {
  @Prop({ type: String }) readonly fieldName!: string
  @Prop({ default: EBCRType.DEFAULT }) customBarcodECameraReaderType!: EBCRType // This will override customBarcodECameraReaderType from mixins
  codeReader: BrowserMultiFormatReader | null = new BrowserMultiFormatReader(
    undefined,
    BARCODE_SCAN.timeBetweenScansMillis as number
  )

  _barcodeDataCapture: BarcodeCapture | undefined
  loading = true
  video: HTMLVideoElement | null = null
  pictureWidth = 320
  pictureHeight = 320
  isCaptured = false
  cameraId = ''
  detectedText = ''

  confirm() {
    this.$emit('update:confirm', this.detectedText.trim())
  }

  defaultScanReader(result: Result, codeReader: BrowserMultiFormatReader) {
    this.isReadyToScan = true
    this.codeReader = codeReader
    const tempResult: { [key: string]: unknown } = { ...result }
    if (!tempResult?.text) {
      return
    }
    this.handleScanResult((tempResult.text as string) || '')
  }

  scanditDataResult(symbology: Barcode) {
    if (!symbology) return
    this.handleScanResult((symbology?.data as string) || '')
  }

  handleScanResult(plainText: string) {
    this.detectedText = plainText
  }

  async mounted() {
    if (this.isCustomVideoReader) {
      await promptVideoPermission()
    }

    await this.initBarcodeReader()
  }

  get isCustomVideoReader() {
    return !(this.customBarcodECameraReaderType === EBCRType.SCANDIT)
  }

  async initBarcodeReader() {
    switch (this.customBarcodECameraReaderType) {
      case EBCRType.SCANDIT:
        try {
          await this.initScanditReader()
        } catch (error) {
          errorHandler(error as TError)
        }
        break
      default:
        try {
          await this.initZxingReader()
        } catch (error) {
          errorHandler(error as TError)
        }
        break
    }
  }

  stopBothVideoAndAudio(stream: MediaStream) {
    stream.getTracks().forEach((track) => {
      if (track.readyState === 'live') {
        track.enabled = false
        track.stop()
      }
    })
  }

  get isScanditReader() {
    return this.customBarcodECameraReaderType === EBCRType.SCANDIT
  }

  async setupVideo() {
    if (!this.video) {
      this.video = document.getElementById('video') as HTMLVideoElement
    }
    const videoSettings = {
      video: {
        facingMode: 'environment',
        width: { ideal: 1000 },
        height: { ideal: 1000 },
        frameRate: {
          ideal: 30,
          max: 60,
        },
        aspectRatio: 1,
      },
    }

    await navigator.mediaDevices
      .getUserMedia(videoSettings)
      .then((stream) => {
        // Setup the video stream
        if (this.video) {
          this.video.srcObject = stream
          const streamSetting = stream.getVideoTracks()[0].getSettings()
          // actual width & height of the camera video
          this.pictureWidth = streamSetting.width || this.pictureWidth
          this.pictureHeight = streamSetting.height || this.pictureHeight
          this.loading = false
        }
      })
      .catch((e) => {
        errorHandler(e)
      })
  }
}
</script>
<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

.margin-ocr {
  margin-top: 10px;
}

.margin-top-reader {
  margin-top: 16px;
}

.step-button {
  display: flex;
  padding-left: 25%;
  padding-right: 25%;
}

.media-container {
  width: 100%;
  position: relative;
  height: 40vh;

  video {
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: 40vh;
  }
}

.media-container:deep() {
  &.scandit-container {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.dectect-input {
  padding: 0 30px 0 30px;
  text-align-last: center;
}

.scan-view {
  text-align: center;
}

.message-wrapper {
  margin-top: 10px;
  word-break: break-word;
  position: absolute;
  z-index: 100;
  color: $pure-white;
  text-align: center;
  padding: 0 2rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.85rem;

  &.top {
    margin-top: 10px;

    >div {
      padding: 8px 12px;
      background: rgb(0, 0, 0, 0.7);
      border-radius: 5px;
    }
  }
}

.image-container {
  max-width: 100%;
  height: 40vh;
  display: none;
  object-fit: cover;
}

.img {
  max-width: 100%;
  height: 40vh;
}

.barcode-scanner {
  height: 60vh;
  background: rgb(32, 32, 32);
  position: relative;
}

.barcode-scanner video {
  width: 100%;
  height: 100%;
}

.scan-area {
  height: 100%;
}
</style>
