<template>
  <div class="edit-view display-in-center view" v-loading.fullscreen="loading || !isReadyToScan">
    <div class="step-label">
      {{ $t('pair_with_keyence_handyterminal') }}
    </div>
    <el-button id="Home_OnClickBarcodeScan" type="primary" class="full-button" @click="startScan">
      <div class="action-button">
        <IconScanQR />
        {{ $t('Scan barcode with keyence HT') }}
      </div>
    </el-button>
    <div class="barcode-number-block">
      <span>
        {{ $t('Number of Scanned Barcodes') }}
      </span>
      <span class="label-box" @click="onCheckScannedBarcode">
        {{ scannedBarcodes.length }}
      </span>
    </div>
    <div v-if="estimateChildCount > 0" class="barcode-number-block">
      <span>
        {{ $t('Number of reserved children barcode') }}
      </span>
      <span class="label-box">
        {{ estimateChildCount }}
      </span>
    </div>
    <ScannerConfig :scannerConfigName="'PairingScan'" :shouldBulkMode=true />
    <el-button circle :disabled="scannedBarcodes.length === 0" type="primary" class="submit-button" @click="forward">
      {{ $t('OK') }}
    </el-button>
  </div>
  <el-dialog :lock-scroll="true" center v-model="dialogVisible" width="90%" top="0" :show-close="false"
    :destroy-on-close="false" :title="checkScannedBarcode ? $t('Scanned Barcodes') : ''">
    <template v-if="checkScannedBarcode">
      <p v-if="scannedBarcodes.length === 0">
        {{ $t('no barcodes') }}
      </p>
      <BarcodeLabelList v-else :barcodes="scannedBarcodes || []" />
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onCloseDialog">
          {{ $t('OK') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import BarcodeLabelList from '@/components/BarcodeLabelList.vue'
import { SAVE_SCANNED_BARCODES, PAIRING, CLEAR_ERROR } from '@/store/actions'
import { IAPIErrorCode, IBarcode, IBarcodeDefinitionType, TError } from 'smartbarcode-web-core/src/utils/types/index'
import { getBarcode, getChildren, getEstimateBarcodeChildren, pairBarcode } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { KeyenceModule, ScanResult } from '@/utils/keyenceHT/keyenceModules'
import {
  getBarcodeIdFromQrCode,
  getProjectCode,
  isQrCode,
  openMessage,
  shouldHandleAsNewBarcode,
} from '@/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import IconScanQR from '@/components/svg/IconScanQR.vue'
import ScannerConfig from '@/components/keyenceHT/ScannerConfig.vue'
@Options({
  components: {
    IconScanQR,
    ScannerConfig: ScannerConfig,
    BarcodeLabelList,
  },
  name: 'Pairscan',
})
export default class PairScan extends Vue {
  prevScannedText = ''
  scannedBarcodeId = ''
  scannedBarcodes: IBarcode[] = []
  barcodes = [] as IBarcode[]
  loading = false
  dialogVisible = false
  checkScannedBarcode = false
  children = [] as IBarcode[]
  estimateChildCount = 0
  extParams = {} as Record<string, unknown>
  isReadyToScan = true

  async created() {
    try {
      this.loading = true
      this.children = await getChildren(this.$route.params.barcodeId)
      const { count } = await getEstimateBarcodeChildren(this.$route.params.barcodeId)
      this.estimateChildCount = count
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.loading = false
    }
  }

  async mounted() {
    const projectCode = getProjectCode(this.$route?.params?.project)

    if (KeyenceModule && KeyenceModule.Scanner) {
      KeyenceModule.Scanner.clearReadCallback()
      KeyenceModule.Scanner.setReadCallback('keyenceHandyTerminalScanResultCallback')
    }

    window.keyenceHandyTerminalScanResultCallback = async (result: ScanResult) => {
      if (result.mDecodeResult !== 'SUCCESS') {
        return
      }
      const scannedText = result.mStringData.trim()
      await this.handleScannedText(scannedText, projectCode)
      this.prevScannedText = scannedText
    }
  }

  async startScan() {
    KeyenceModule.Scanner.startRead()
  }

  get currentBarcodeId(): string {
    return this.$store.state.barcode?.barcode?.id
  }

  openErrorToast(message: string) {
    openMessage(message, 'error', 0)
  }

  get barcodeName(): string {
    return this.barcodeType?.name || ''
  }

  get currentTrackPointName() {
    return this.project?.trackPoints[this.currentTrackPointKey]?.name
  }

  errorVariableTemplate() {
    return {
      barcodeType: this.barcodeName,
      fTP1: this.currentTrackPointName,
      tTP2: this.nextSelectTrackingPointName,
    }
  }

  async handleScannedText(plainText: string, projectCode: string) {
    if (!shouldHandleAsNewBarcode(plainText, this.prevScannedText, this.prevBarcodeId)) {
      return
    }
    const shouldTreatAsQrCode = isQrCode(plainText)
    const barcodeId = shouldTreatAsQrCode ? getBarcodeIdFromQrCode(plainText) : `projectCode=${projectCode}`
    const externalId = shouldTreatAsQrCode ? undefined : plainText
    if (!barcodeId) {
      return
    }

    try {
      const scannedBarcode: IBarcode = await getBarcode(barcodeId, externalId)
      if (this.scannedBarcodes.find((barcode) => barcode.id === scannedBarcode?.id)) {
        return
      }

      this.validateBarcodeForPairing(scannedBarcode)
      await this.doPairingBarcode(scannedBarcode.id, this.parentBarcodeId)

      this.scannedBarcodeId = scannedBarcode.id
      const isAlreadyAdded =
        this.barcode?.id === scannedBarcode.id ||
        this.scannedBarcodes.find((barcode) => barcode.id === scannedBarcode.id)
      if (!isAlreadyAdded) {
        this.scannedBarcodes.push(scannedBarcode)
      }

      openMessage(this.$t('barcode detected'), 'success')
    } catch (error) {
      this.currentPlainText = ''
      if (error === 'cancel') {
        return
      }

      if (typeof error === 'object' && error?.hasOwnProperty('id')) {
        const message = this.$t(`errors.${(error as IAPIErrorCode)?.id?.pop()}`)
        this.openErrorToast(message)
        return
      }
      if (typeof error === 'string') {
        const modelObject = this.errorVariableTemplate()
        errorHandler(error as TError, modelObject)
        return
      }
      if (!isEmpty(error)) {
        const message = JSON.stringify(error)
        this.openErrorToast(message)
      }
    }
  }

  onCheckScannedBarcode() {
    this.checkScannedBarcode = true
    this.dialogVisible = true
  }

  onCloseDialog() {
    this.dialogVisible = false
    this.checkScannedBarcode = false
  }

  barcodeTypeInfo(barcodeType: string): Record<string, IBarcodeDefinitionType> {
    return this.$store.state.project?.details?.barcodeTypes[barcodeType]
  }

  get isAllowForcePairing() {
    return this.$store.state.project?.details?.allowForcePairing || false
  }

  isBarcodeInChildrenList(barcode: IBarcode): boolean {
    return !!this.children.find((bc) => bc.id === barcode.id)
  }

  validateBarcodeForPairing(scannedBarcode: IBarcode) {
    const currentProjectId = this.$store.state.project.details.id
    const scannedBCProjectId = scannedBarcode.projectId
    if (currentProjectId !== scannedBCProjectId) {
      throw this.$t('scanned_barcode_not_same_project')
    }

    const { barcodeType, hasParent } = scannedBarcode

    if (this.parentBarcodeId === scannedBarcode?.id) {
      throw this.$t('barcode_pairing_by_itself_error')
    }

    if (!barcodeType) {
      throw this.$t('barcode not activated')
    }

    if (this.isBarcodeInChildrenList(scannedBarcode)) {
      throw this.$t('scanned_barcode_in_children_list')
    }

    if (hasParent && !this.isAllowForcePairing) {
      throw this.$t('barcode_already_paired')
    }

    const barcodeTypeItem = this.barcodeTypeInfo(barcodeType)
    if (!barcodeTypeItem?.allowToBePaired || scannedBarcode.isDeactivated || !scannedBarcode.isReadyToAddTrackData) {
      throw this.$t('barcode_can_not_be_paired')
    }
    if (
      scannedBarcode?.reservedParentIds?.length > 0 &&
      !scannedBarcode?.reservedParentIds?.includes(this.parentBarcodeId)
    ) {
      throw this.$t('errors.3046')
    }
  }

  async doPairingBarcode(barcodeId: string, parentBarcodeId: string) {
    const pairBarcodeRequest = {
      parentBarcodeId: parentBarcodeId,
      childrenBarcodeIds: [barcodeId],
      isDryRun: true,
    }
    try {
      await pairBarcode(pairBarcodeRequest)
    } catch (error) {
      if (error === '3044' && this.isAllowForcePairing) {
        await this.$confirm('', this.$t('confirm_overwrite_parent'), {
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'danger',
          cancelButtonText: this.$t('cancel'),
        })
          .then(async () => {
            const payload = {
              ...pairBarcodeRequest,
              forcePairing: true,
            }
            await pairBarcode(payload)
            this.extParams = {
              ...this.extParams,
              forcePairing: true,
            }
          })
          .catch((err: unknown) => {
            throw err
          })
          .finally(() => this.$store.commit(CLEAR_ERROR))
        return
      }
      throw error
    }
  }

  get parentBarcodeId() {
    return this.$store.state.barcode?.barcode?.id || ''
  }

  forward() {
    this.$store.dispatch(SAVE_SCANNED_BARCODES, this.scannedBarcodes)
    this.$router.push({
      name: 'scanned-barcode-list',
      params: {
        title: this.$t('pairing_read_result'),
        operation: PAIRING,
        extParams: JSON.stringify(this.extParams),
      },
    })
  }

  unmounted() {
    if (KeyenceModule && KeyenceModule.Scanner) {
      KeyenceModule.Scanner.clearReadCallback()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

.scan-area {
  height: 100%;
}

.edit-view.view {
  padding-left: 0;
  padding-right: 0;
}

.step-label {
  margin-bottom: 25px;
}
</style>
