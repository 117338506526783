<template>
  <SvgBase width="36" height="36" viewBox="0 0 24 24" fill="none" :style="transform">
    <circle cx="5" cy="12" r="2" :fill="color" />
    <circle cx="12" cy="12" r="2" :fill="color" />
    <circle cx="19" cy="12" r="2" :fill="color" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'
@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
    rotation: {
      type: Number,
      default: 0,
    },
  },
})
export default class IconMore extends Vue {
  get transform() {
    return `transform: rotate(${this.rotation}deg);`
  }
}
</script>
