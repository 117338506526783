import store from '@/store'
import { CLEAR_PROFILE } from '@/store/actions'
import axios, { AxiosRequestConfig } from 'axios'
import isEmpty from 'lodash/isEmpty'
import { getCurrentUtcOffset, getVisitorId } from './helpers'
import { shouldUpdateClientVersion } from 'smartbarcode-web-core/src/utils/helpers'
import { version } from '../../package.json'
import { SB_FORCE_UPGRADE_VERSION_KEY, SB_MOBILE_VERSION_KEY } from 'smartbarcode-web-core/src/utils/constants'
export * from '@/apis/barcodeAPI'
export * from '@/apis/locationAPI'
export * from '@/apis/userAPI'
export const baseUrl = `${process.env.VUE_APP_API_HOST}`

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
  withCredentials: true,
})

const PROFILE_URL = '/user/profile'
const REFRESH_TOKEN_URL = '/user/refresh-token'
export async function refreshToken() {
  const payload = { visitorId: await getVisitorId() }
  return await axiosInstance.post(REFRESH_TOKEN_URL, payload)
}

interface IProcess {
  resolve: Function
  reject: Function
}
let isRefreshing = false
let failedQueue = [] as IProcess[]
const processQueue = (error: Error | null, isRefreshDone = false) => {
  failedQueue.forEach((prom) => {
    if (error) prom.reject(error)
    else prom.resolve(isRefreshDone)
  })

  failedQueue = []
}

axiosInstance.interceptors.request.use((config) => {
  config.headers!['UTC-Offset'] = getCurrentUtcOffset()
  config.headers![SB_MOBILE_VERSION_KEY] = version
  return config
})

axiosInstance.interceptors.request.use(
  (config) => {
    const isRefreshTokenRequest = config.url === REFRESH_TOKEN_URL
    if (isRefreshing && !isRefreshTokenRequest) {
      return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
        .then(() => config)
        .catch((err) => Promise.reject(err))
    }
    isRefreshing = config.url === PROFILE_URL

    return config
  },
  (error) => Promise.reject(error)
)

export async function execRefreshingProcess(originalRequest: AxiosRequestConfig) {
  try {
    isRefreshing = true
    await refreshToken()
    if (!isEmpty(originalRequest.data)) {
      originalRequest.data = JSON.parse(originalRequest.data)
    }

    return axiosInstance(originalRequest)
  } catch (err) {
    store.commit(CLEAR_PROFILE)
  } finally {
    isRefreshing = false
    processQueue(null, true)
  }
}

function checkVersion(sbVersion: string | undefined, forceUpgrade: string | undefined) {
  if (sbVersion === null) return
  const clientVersion = version

  // Since value of headers are string we need to parse to boolean
  const isForceUpgrade = forceUpgrade?.toLowerCase() === 'true'

  if (sbVersion) {
    if (shouldUpdateClientVersion(clientVersion, sbVersion)) {
      document.dispatchEvent(new CustomEvent('onUpdateVersionNotification', { detail: { isForceUpgrade } }))
    }
  }
}

axiosInstance.interceptors.response.use(
  (response) => {
    try {
      if (store.getters.isAuth && response.config.url !== REFRESH_TOKEN_URL && isEmpty(response.headers['user-type'])) {
        execRefreshingProcess(response.config)
      }
      if (response.config.url === PROFILE_URL) {
        isRefreshing = false
        processQueue(null, true)
      }
      const sbVersion = response?.headers?.[SB_MOBILE_VERSION_KEY]
      const isForceUpgrade = response?.headers?.[SB_FORCE_UPGRADE_VERSION_KEY]
      checkVersion(sbVersion, isForceUpgrade)
    } catch (e) {}
    return response
  },
  async (error) => {
    const errors = error.response?.data?.errors
    const sbVersion = error?.response?.headers?.[SB_MOBILE_VERSION_KEY]
    const isForceUpgrade = error?.response?.headers?.[SB_FORCE_UPGRADE_VERSION_KEY]
    checkVersion(sbVersion, isForceUpgrade)
    switch (error.response?.status) {
      case 400:
        if (errors?.$common) {
          throw errors.$common[0]
        } else {
          throw errors || error.response?.data
        }
      case 401:
        return execRefreshingProcess(error.config)

      case 403:
        throw new Error('403')

      case 500:
        throw new Error('500')

      default:
        throw new Error(error)
    }
  }
)

export async function customRequest(payload: AxiosRequestConfig<unknown>) {
  return (await axiosInstance.request(payload)).data
}
