<template>
  <template v-if="!isHideSubmitButton">
    <template v-for="(button, index) in buttons" :key="index">
      <component :is="button.inputType || 'el-button'" :id="button.id" circle @click="handleClick(button)"
        :type="button.type || 'primary'" :native-type="button.nativeType" v-if="button.inputType !== 'el-radio-group'"
        class="submit-button">
        <IconArrow v-if="button.hasNextStep || button.isShowArrow" />
        <span v-else>{{ button.label }}</span>
      </component>

      <el-radio-group v-if="button.inputType === 'el-radio-group'" v-model="button.modelValue">
        <el-radio v-for="option in button.options" :key="option.value" :label="option.value"
          @change="handleClick(button)">
          {{ option.label }}
        </el-radio>
      </el-radio-group>
    </template>
  </template>

  <FloatingButton v-if="!isHideSubmitButton && !isTopButtonVisible && isShownFloatingActionButton" :buttons="buttons"
    :isExpanded="isExpanded" @toggle-expand="toggleExpand" :handleClick="handleClick" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconArrow from '@/components/svg/IconArrow.vue'
import IconMore from '@/components/svg/IconMore.vue'
import { Prop } from 'vue-property-decorator'
import { IFormButton } from './FormBuilder.vue'
import FloatingButton from './FloatingButtonFormBuilder.vue'
@Options({
  components: { IconArrow, IconMore, FloatingButton },
})
export default class ButtonFormBuilder extends Vue {
  @Prop({ type: Boolean }) readonly isHideSubmitButton: boolean = false
  @Prop({ type: Boolean }) readonly hasNextStep: boolean = false
  @Prop({ type: Boolean }) readonly isShowArrow: boolean = false
  @Prop({ type: Array as () => IFormButton[] }) readonly buttons!: IFormButton[];
  @Prop({ type: Boolean }) readonly isShownFloatingActionButton?: boolean
  topButtonVisible = true;
  isExpanded = false;

  get isTopButtonVisible() {
    return this.topButtonVisible
  }

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.checkButtonVisibility()
  }

  handleScroll() {
    this.checkButtonVisibility()
  }

  handleClick(button: { onClick: () => void }) {
    if (button.onClick) {
      button.onClick()
    }
  }

  checkButtonVisibility() {
    const btn = this.buttons[0]
    const button = document.getElementById(btn.id || 'FormBuilder_Submit')
    if (button) {
      const rect = button.getBoundingClientRect()
      this.topButtonVisible = rect.top >= 0 && rect.bottom <= window.innerHeight
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded
  }
}
</script>
