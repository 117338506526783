import { axiosInstance } from '@/utils/api'
import { ELinkType } from '@/utils/enums'
import { removeEmpty } from '@/utils/helpers'
import {
  IActivateRequestBody,
  IBarcode,
  IBarcodeSearchForm,
  IPairRequestBody,
  ITrackingRequestBody,
  IUnpairRequest,
} from 'smartbarcode-web-core/src/utils/types/index'

export async function getProject(code: string, version?: string) {
  const response = await axiosInstance.get('/barcode/project', {
    params: {
      code,
      ...(version && { version }),
    },
  })
  return response?.data
}

export async function getProjectById(id: string) {
  const response = await axiosInstance.get('/barcode/project', {
    params: {
      id,
    },
  })
  return response?.data
}

export async function getProducts() {
  const response = await axiosInstance.get('/barcode/products')
  return response?.data
}

export async function generateBarcode(projectCode: string, projectVersion: string) {
  const response = await axiosInstance.post('/barcode/barcode', {
    projectCode,
    projectVersion,
  })
  return response?.data
}

export async function getBarcodeByTNorExtID(params: {
  projectCode: string
  externalId?: string
  trackingNumber?: string
}) {
  const response = await axiosInstance.get('/barcode/barcode', { params: { ...params } })
  return response?.data
}

export async function getBarcode(id: string, externalId?: string, trackingNumber?: string) {
  const params = id.includes('=')
    ? removeEmpty({
        projectCode: id.split('=')[1],
        externalId,
        trackingNumber,
      })
    : removeEmpty({ id })

  const response = await axiosInstance.get('/barcode/barcode', {
    params: { ...params },
  })
  return response?.data
}

export async function getNotification(id: string) {
  const response = await axiosInstance.get('/barcode/barcode-notifications', {
    params: { id },
  })
  return response?.data
}

export async function getBarcodeByPath(id: string, path: string) {
  const response = await axiosInstance.get('/barcode/linked-barcode', { params: { id, path } })
  return response?.data
}

export async function activateBarcode(barcodeData: IActivateRequestBody) {
  const response = await axiosInstance.post('/barcode/activation', barcodeData)
  return response?.data
}

export async function updateBarcode(barcodeData: Partial<IActivateRequestBody>) {
  const response = await axiosInstance.put('/barcode/activation', barcodeData)
  return response?.data
}

export async function pairBarcode(request: IPairRequestBody) {
  const response = await axiosInstance.post('/barcode/pairing', request)
  return response?.data
}
export async function unpairBarcode(request: IUnpairRequest) {
  const response = await axiosInstance.post('/barcode/unpairing', request)
  return response?.data
}

export async function getChildren(id: string) {
  const response = await axiosInstance.get('/barcode/children', {
    params: {
      id,
    },
  })
  return response?.data
}

export async function getRecycleHistory(id: string, skip: number, count: number) {
  const response = await axiosInstance.get('/barcode/history', {
    params: {
      id,
      skip,
      count,
    },
  })
  return response?.data
}

export async function tracking(trackingData: ITrackingRequestBody) {
  const response = await axiosInstance.post('/barcode/tracking', trackingData)
  return response?.data
}

export async function undoTracking(barcodeId: string) {
  const response = await axiosInstance.post('/barcode/undo-tracking', {
    barcodeId: barcodeId,
  })
  return response?.data
}

export async function uploadImage(payload: unknown) {
  const response = await axiosInstance.post('/barcode/file', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return response?.data
}

export async function removeImage(filePath: string) {
  const response = await axiosInstance.delete('/barcode/file', { data: { filePath } })
  return response?.data
}

export async function recycleBarcode(barcodeID: string) {
  const response = await axiosInstance.post('/barcode/recycle', { barcodeId: barcodeID })
  return response
}

export async function getChildrenValue(id: string, key: string) {
  const response = await axiosInstance.get('/barcode/children/value', {
    params: { id, key },
  })
  return response?.data
}

interface LinkingBCPayload {
  isDryRun?: boolean
  barcodeId: string
  targetBarcodeIds: string[]
}
export async function linkingBarcodes(type: ELinkType, data: LinkingBCPayload) {
  const mappingNewType = type === ELinkType.LINK_TO ? 'linking' : type

  const response = await axiosInstance.post(`/barcode/${mappingNewType}`, data)
  return response
}

export async function undolinkingBarcode(barcodeId: string) {
  const response = await axiosInstance.post('/barcode/undo-linking', {
    barcodeId: barcodeId,
  })
  return response
}

export async function reconcileBarcode(barcodeId: string, index: number) {
  const response = await axiosInstance.post('barcode/ledger/reconcile', {
    barcodeId,
    index,
  })
  return response?.data
}

export async function getBarcodeLedgerStatus(id: string, index: number) {
  const response = await axiosInstance.get('/barcode/ledger/status', {
    params: { id, index },
  })
  return response?.data
}

export async function getEstimateBarcodeChildren(barcodeId: string) {
  const response = await axiosInstance.get('/barcode/pairing/reserved-count', {
    params: { barcodeId },
  })
  return response?.data
}

export async function getEstimateBarcodeLinked(barcodeId: string) {
  const response = await axiosInstance.get('/barcode/linking/reserved-count', {
    params: { barcodeId },
  })
  return response?.data
}

export async function compileMessage(barcodeId: string, template: string) {
  return (await axiosInstance.post('/barcode/compile-message', { barcodeId, template }))?.data
}

export async function getUserTrackingCount(date: string, projectId: string, utcOffset: number) {
  return await axiosInstance.get('/barcode/tracking-count', {
    params: {
      date,
      projectId,
      utcOffset,
    },
  })
}

export async function getBarcodeList(data: { condition: IBarcodeSearchForm }) {
  const response = await axiosInstance.post('/barcode/search', data)
  return response?.data
}

export async function updateReferenceFieldBarcode(barcodeId: string) {
  const response = await axiosInstance.get('/barcode/reference-data', {
    params: { barcodeId },
  })
  return response?.data
}

interface IReviewCalculationPayload {
  field: string
  startTrackPointKey?: string
  endTrackPointKey?: string
  customFieldKey: string
  barcode: IBarcode
}
export async function reviewCalculation(reviewPayload: IReviewCalculationPayload) {
  const response = await axiosInstance.post('/barcode/review-calculation', reviewPayload)
  return response?.data
}
