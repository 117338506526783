<template>
  <div class="base-input-block my-2">
    <el-autocomplete :id="`InputNumber_${name}`" :name="`InputNumber_${name}`" v-model="_modelValue"
      :fetch-suggestions="suggestingItems" inputmode="tel" :placeholder="placeholder" :disabled="disabled" clearable
      class="inline-input w-full" @change="onChanged">
      <template #default="{ item }">
        <span class="font-bold">{{ item.name !== '' ? `${item.name}: ` : '' }}</span>
        <span>{{ formattedPhoneNumber(item.value) }}</span>
      </template>
      <template #prefix>
        <span>{{ `${country?.dialCode || ''}` }}</span>
      </template>
    </el-autocomplete>
    <span v-show="!disableError && error" class="error-msg">{{ error }}</span>
  </div>
</template>

<script lang="ts">
import { maskPhoneNum } from 'smartbarcode-web-core/src/utils/barcodeHelper'
import { OTHER_COUNTRY_LIST } from 'smartbarcode-web-core/src/utils/constants'
import { IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'
@Options({
  emits: ['update:modelValue'],

  methods: { maskPhoneNum },
})
export default class InputPhoneNumber extends Vue {
  @PropSync('modelValue', { type: String }) _modelValue?: string
  @Prop({ type: String }) readonly name?: string = ''
  @Prop({ type: String }) readonly countryCode!: string
  @Prop({ type: String }) readonly placeholder?: string = ''
  @Prop({ type: Boolean, default: true }) readonly isSuggestion?: boolean = true
  @Prop({ type: Boolean }) readonly disabled?: boolean = false
  @Prop({ type: Boolean }) readonly disableError?: boolean
  @Prop({ type: String }) readonly error?: string

  @InjectReactive() currentBarcodeType!: string

  get currentBCType() {
    const bcTypeCode = this.$store.getters.barcodeType ?? this.currentBarcodeType
    const project = this.$store.state.project.details as IProject
    return project.barcodeTypes[bcTypeCode]
  }

  get projectNotificationPhoneNumber() {
    const routes = this.currentBCType.trackPointRouteNotifications ?? []
    const items = routes.reduce(
      (all, e) =>
        new Set([
          ...all,
          ...(e.smsDestinations.filter((e) => e.countryCode === this.countryCode).map((e) => e.number) ?? []),
        ]),
      new Set([] as string[])
    )
    return [...items]
  }

  get predefinedPhoneNumbers() {
    const phoneNumbers = (this.currentBCType.predefinedContact?.numberContacts ?? [])
      .filter((e) => e.countryCode === this.countryCode)
      .map((i) => ({
        name: i.name,
        number: i.number,
      }))
    return [...phoneNumbers]
  }

  @Watch('_modelValue')
  onChanged(val: string) {
    this._modelValue = this.formattedPhoneNumber(val)
  }

  @Watch('placeholder')
  onPlaceholderChanged() {
    this._modelValue = this.formattedPhoneNumber(this._modelValue || '')
  }

  formattedPhoneNumber(value: string): string {
    return maskPhoneNum(value, this.countryCode)
  }

  suggestingItems(queryString: string, callback: Function) {
    const suggestions = this.isSuggestion
      ? this.projectNotificationPhoneNumber
        .map((i) => ({ name: '', number: i }))
        .concat(this.predefinedPhoneNumbers)
        .map((e) => ({ name: e.name, value: e.number }))
      : []

    callback(suggestions)
  }

  get country() {
    return OTHER_COUNTRY_LIST[this.countryCode as keyof typeof OTHER_COUNTRY_LIST]
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';

.half-width {
  width: 40%;
}

.align-middle {
  vertical-align: middle;
}

.field-input {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;

  &.error .el-input {
    border-color: $error;
  }

  .error-msg {
    color: $error;
    line-height: 1.2;
  }
}

.ocr-camera {
  position: absolute;
  right: 30px;
  cursor: pointer;
}

el-dialog {
  height: 100vh;
  width: 100%;
}

.base-input-block {
  position: relative;

  &__child {
    position: absolute;
    bottom: 0%;
    right: 10%;
  }
}

:deep() {
  .el-input__prefix {
    left: 10px;
  }

  .el-input__inner {
    padding-left: 52px;
  }
}
</style>
