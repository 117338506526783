<template>
  <div class="edit-view display-in-center view" v-loading.fullscreen="loading">
    <div class="step-label">
      {{ $t('bulk_skip_tracking') }}
    </div>

    <el-button id="Home_OnClickBarcodeScan" type="primary" class="full-button" @click="startScan">
      <div class="action-button">
        <IconScanQR />
        {{ $t('Scan barcode with keyence HT') }}
      </div>
    </el-button>

    <div class="barcode-number-block">
      <span>
        {{ $t('Number of Scanned Barcodes') }}
      </span>
      <span class="label-box" @click="onCheckScannedBarcode">
        {{ scannedBarcodes.length }}
        {{ bulkUpdateOperationLimitCount ? `/${bulkUpdateOperationLimitCount?.maxValue}` : '' }}
      </span>
    </div>
    <ScannerConfig :scannerConfigName="'BulkScan'" :shouldBulkMode=true />
    <el-button circle type="primary" class="submit-button"
      :disabled="scannedBarcodes.length < (bulkUpdateOperationLimitCount ? bulkUpdateOperationLimitCount?.minValue : 1)"
      @click="forward">
      {{ $t('OK') }}
    </el-button>
  </div>
  <el-dialog center :lock-scroll="true" v-model="dialogVisible" width="90%" top="0" :show-close="false"
    :destroy-on-close="false" :title="checkScannedBarcode ? $t('Scanned Barcodes') : ''">
    <template v-if="checkScannedBarcode">
      <p v-if="scannedBarcodes.length === 0">
        {{ $t('no barcodes') }}
      </p>
      <BarcodeLabelList v-else :barcodes="scannedBarcodes || []" />
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onCloseDialog">
          {{ $t('Done') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import BarcodeLabelList from '@/components/BarcodeLabelList.vue'
import { ADD_TRACKING, FETCH_BARCODE, SAVE_SCANNED_BARCODES } from '@/store/actions'
import { IAPIErrorCode, IBarcode, IProject, ITrackingRequestBody, TError } from 'smartbarcode-web-core/src/utils/types/index'
import { getBarcode, tracking } from '@/utils/api'
import BarcodeSymbologiesSelection from '@/components/BarcodeSymbologiesSelection.vue'
import { cloneDeep } from 'lodash'
import { KeyenceModule, ScanResult } from '@/utils/keyenceHT/keyenceModules'
import {
  getBarcodeIdFromQrCode,
  getProjectCode,
  isQrCode,
  openMessage,
  shouldHandleAsNewBarcode,
} from '@/utils/helpers'
import errorHandler from '@/utils/errorHandler'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import IconScanQR from '@/components/svg/IconScanQR.vue'
import ScannerConfig from '@/components/keyenceHT/ScannerConfig.vue'

@Options({
  components: {
    IconScanQR,
    ScannerConfig: ScannerConfig,
    BarcodeLabelList,
    BarcodeSymbologiesSelection,
  },
  name: 'BulkTracking',
})
export default class BulkSkipBarcodeTracking extends Vue {
  prevScannedText = ''
  scannedBarcodeId = ''
  scannedBarcodes: IBarcode[] = []
  loading = false
  dialogVisible = false
  checkScannedBarcode = false
  requestBody = {} as ITrackingRequestBody
  nextSelectTrackingPointName = ''

  get barcode(): IBarcode {
    return this.$store.state.barcode.barcode
  }

  async created() {
    if (!this.$store.state.barcode.barcode) {
      await this.$store.dispatch(FETCH_BARCODE, {
        id: this.$route.params.barcodeId,
        trackingNumber: this.trackingNumberFromPath,
        externalId: this.externalIdFromPath,
        path: this.queryPath,
      })
    }
    this.nextSelectTrackingPointName = this.$route.params.nextTrackName
    this.requestBody = JSON.parse(localStorage.tempRequestForMultipleBarcodeScan)
    this.scannedBarcodes.push(cloneDeep(this.barcode))
  }

  async mounted() {
    const projectCode = getProjectCode(this.$route?.params?.project)

    if (KeyenceModule && KeyenceModule.Scanner) {
      KeyenceModule.Scanner.clearReadCallback()
      KeyenceModule.Scanner.setReadCallback('keyenceHandyTerminalScanResultCallback')
    }

    window.keyenceHandyTerminalScanResultCallback = async (result: ScanResult) => {
      if (result.mDecodeResult !== 'SUCCESS') {
        return
      }
      const scannedText = result.mStringData.trim()
      await this.handleScannedText(scannedText, projectCode)
      this.prevScannedText = scannedText
    }
  }

  async startScan() {
    KeyenceModule.Scanner.startRead()
  }

  get bulkUpdateOperationLimitCount() {
    const project = this.$store.state.project.details as IProject
    const projectBarcode = project.barcodeTypes?.[this.$store.getters.barcodeType]
    return projectBarcode?.bulkUpdateOperationLimitCount
  }

  get currentBarcodeId(): string {
    return this.$store.state.barcode?.barcode?.id
  }

  openErrorToast(message: string) {
    openMessage(message, 'error', 0)
  }

  get barcodeName(): string {
    return this.barcodeType?.name || ''
  }

  get currentTrackPointName() {
    return this.project?.trackPoints[this.currentTrackPointKey]?.name
  }

  errorVariableTemplate() {
    return {
      barcodeType: this.barcodeName,
      fTP1: this.currentTrackPointName,
      tTP2: this.nextSelectTrackingPointName,
    }
  }

  addBarcode(barcode: string) {
    ; (this.requestBody?.barcodeIds as string[]).push(barcode)
  }

  onCheckScannedBarcode() {
    this.checkScannedBarcode = true
    this.dialogVisible = true
  }

  onCloseDialog() {
    this.dialogVisible = false
    this.checkScannedBarcode = false
  }

  async handleScannedText(plainText: string, projectCode: string) {
    if (!shouldHandleAsNewBarcode(plainText, this.prevScannedText, this.prevBarcodeId)) {
      return
    }
    const shouldTreatAsQrCode = isQrCode(plainText)
    const barcodeId = shouldTreatAsQrCode ? getBarcodeIdFromQrCode(plainText) : `projectCode=${projectCode}`
    const externalId = shouldTreatAsQrCode ? undefined : plainText
    if (!barcodeId) {
      return
    }

    try {
      const scannedBarcode: IBarcode = await getBarcode(barcodeId, externalId)
      if (this.scannedBarcodes.find((barcode) => barcode.id === scannedBarcode?.id)) {
        return
      }

      this.validateBarcodeForBulkTracking(scannedBarcode)

      const trackingRequest = {
        barcodeId: this.barcode?.id,
        barcodeIds: [scannedBarcode?.id],
        trackingData: this.requestBody.trackingData,
        selectedNextTrackpoint: this.nextSelectTrackingPointName,
        isDryRun: true,
        isSkip: true,
      }
      await tracking(trackingRequest)

      this.scannedBarcodeId = scannedBarcode.id
      const isAlreadyAdded =
        this.barcode?.id === scannedBarcode.id ||
        this.scannedBarcodes.find((barcode) => barcode.id === scannedBarcode.id)
      if (!isAlreadyAdded) {
        this.scannedBarcodes.push(scannedBarcode)
        this.addBarcode(scannedBarcode.id)
      }

      openMessage(this.$t('barcode detected'), 'success')
    } catch (error) {
      this.currentPlainText = ''
      if (error === 'cancel') {
        return
      }

      if (typeof error === 'object' && error?.hasOwnProperty('id')) {
        const message = this.$t(`errors.${(error as IAPIErrorCode)?.id?.pop()}`)
        this.openErrorToast(message)
        return
      }
      if (typeof error === 'string') {
        const modelObject = this.errorVariableTemplate()
        errorHandler(error as TError, modelObject)
        return
      }
      if (!isEmpty(error)) {
        const message = JSON.stringify(error)
        this.openErrorToast(message)
      }
    }
  }

  async validateBarcodeForBulkTracking(resultScanned: IBarcode) {
    const currentProjectId = this.$store.state.project.details.id
    const scannedBCProjectId = resultScanned.projectId
    if (currentProjectId !== scannedBCProjectId) {
      throw this.$t('scanned_barcode_not_same_project')
    }

    const { barcodeType } = resultScanned
    if (!barcodeType) {
      throw this.$t('barcode not activated')
    }
  }

  forward() {
    const newBarcodes = this.scannedBarcodes.filter((barcode) => barcode.id !== this.barcode.id)
    this.$store.dispatch(SAVE_SCANNED_BARCODES, newBarcodes)
    this.$router.push({
      name: 'scanned-barcode-list',
      params: {
        title: this.$t('bulk_skip_tracking_read_result'),
        operation: ADD_TRACKING,
        extParams: JSON.stringify({
          trackingData: this.requestBody.trackingData,
          selectedNextTrackpoint: this.nextSelectTrackingPointName,
          isSkip: true,
        }),
      },
    })
  }

  unmounted() {
    if (KeyenceModule && KeyenceModule.Scanner) {
      KeyenceModule.Scanner.clearReadCallback()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

.edit-view.view {
  padding-left: 0;
  padding-right: 0;
}

.step-label {
  margin-bottom: 25px;
}

.barcode-number-block {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
