import { ELinkType } from './../utils/enums'
// App
export const OPEN_DIALOG = 'app/openDialog'
export const CLOSE_DIALOG = 'app/closeDialog'
export const REGISTER_TRANSLATE = 'app/registerTranslate'
export const SET_PAGE_NOT_FOUND = 'app/setPage404'
export const RESET_PAGE_NOT_FOUND = 'app/resetPage404'

// Profile
export const SAVE_PROFILE = 'profile/saveProfile'
export const FETCH_PROFILE = 'profile/fetchProfile'
export const LOGOUT = 'profile/logout'
export const CLEAR_PROFILE = 'profile/clearProfile'
export const SAVE_ADDRESS = 'profile/saveAddress'
export const FETCH_TRACKING_COUNT = 'profile/fetchTrackingCount'
export const SAVE_TRACKING_COUNT = 'profile/saveTrackingCount'
export const CLEAR_TRACKING_COUNT = 'profile/clearTrackingCount'
// Project
export const FETCH_PROJECT = 'project/fetchProject'
export const FETCH_PROJECT_BY_ID = 'project/fetchProjectById'
export const SAVE_PROJECT = 'project/saveProject'
export const FETCH_PRODUCTS = 'project/fetchProducts'
export const SAVE_PRODUCTS = 'project/saveProducts'
export const INIT_DONE = 'project/initDone'

// Barcode
export const SAVE_BARCODE_SEARCH_PARAMS = 'barcode/saveBarcodeSearchParams'
export const UPDATE_SCANNED_RESULT = 'barcode/updateScannedResult'
export const RESET_SCANNED_BARCODE_BLOB = 'barcode/resetScannedBarcodeBlob'
export const UPDATE_ACTIVATION_DATA_BARCODE = 'barcode/updateActivationDataBarcode'
export const SAVE_BARCODE = 'barcode/saveBarcode'
export const GENERATE_BARCODE = 'barcode/generateBarcode'
export const FETCH_BARCODE = 'barcode/fetchBarcode'
export const CLEAR_BARCODE = 'barcode/clearBarcode'
export const UNPAIR = 'barcode/unpair'
export const BARCODE_ACTION_COMPLETE = 'barcode/actionComplete'
export const NUMBER_BARCODE_ACTION_COMPLETE = 'barcode/numberOfBCActionComplete'
export const UNDO_TRACKING = 'barcode/undoTracking'
export const BARCODE_SELECTED_TYPE = 'barcode/selectedType'
export const SET_BARCODE_FORM_STEP = 'barcode/setFormStep'
export const SAVE_SCANNED_BARCODES = 'barcode/saveBarcodesToPool'
export const UPDATE_SCANNED_UPLOAD_STATUS = 'barcode/updateScannedBarcodeStatus'
export const CLEAR_SCANNED_UPLOAD_STATUS = 'barcode/clearScannedBarcodeStatus'

export const ADD_TRACKING = 'barcode/addTracking'
export const PAIRING = 'barcode/pairing'
export const UNPAIRING = 'barcode/unpairing'
// export const LINKING = 'barcode/link-to'
export const LINK_TO = `barcode/${ELinkType.LINK_TO}`
export const BE_LINKED = `barcode/${ELinkType.BE_LINKED}`
export const ERROR_OCCUR = 'barcode/error'
export const CLEAR_ERROR = 'barcode/clearError'

// Notification
export const SAVE_NOTIFICATION = 'notification/saveNotification'
export const FETCH_NOTIFICATION = 'notification/fetchNotification'
export const CLEAR_NOTIFICATION = 'notification/clearNotification'
export const OPEN_NOTIFICATION_DIALOG = 'notification/openNotificationDialog'
export const CLOSE_NOTIFICATION_DIALOG = 'notification/closeNotificationDialog'

// Location
export const FETCH_LOCATIONS = 'location/fetchLocations'
export const INSTOCK_LOCATION = 'location/instock'
export const OUTSTOCK_LOCATION = 'location/outstock'
