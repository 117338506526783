
import BaseInput from '@/components/BaseInput.vue'
import ButtonFormBuilder from '@/components/ButtonFormBuilder.vue'
import IconArrow from '@/components/svg/IconArrow.vue'
import { UPDATE_SCANNED_UPLOAD_STATUS } from '@/store/actions'
import { ECustomFieldType, EFileKind, ECustomButtonFormBuilderType } from 'smartbarcode-web-core/src/utils/enums/index'
import { validateEmail } from '@/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { TFormSchema } from 'smartbarcode-web-core/src/utils/types/index'
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import { GhgEmissionInput } from 'smartbarcode-web-core/src/utils/ghgEmission/GhgEmissionInput'
import { Options, Vue, mixins } from 'vue-class-component'
import { InjectReactive, Prop } from 'vue-property-decorator'
import { GhgEmissionCalculationType } from 'smartbarcode-web-core/src/utils/ghgEmission/GhgEmissionCalculationType'

export interface IPromiseHandlers {
  resolve: Function
  reject: Function
}

export interface IFormButton {
  id: string
  nativeType: string
  label: string
  hasNextStep?: boolean
  isShowArrow?: boolean
  type?: string
  inputType?: ECustomButtonFormBuilderType
  onClick: () => void
  options?: Array<{ value: string, label: string }>
  modelValue?: string
}

@Options({
  components: { BaseInput, IconArrow, ButtonFormBuilder },
  emits: ['submit', 'validate', 'reviewCalculation'],
  name: 'FormBuilder',
})
export default class FormBuilder extends Vue {
  @InjectReactive() isShowArrow!: boolean
  @Prop({ type: Object }) readonly formSchema: TFormSchema = {}
  @Prop({ type: Object }) readonly formModel: Record<string, unknown> = {}
  @Prop({ type: Array as () => IFormButton[] }) readonly passedButtons!: IFormButton[];
  @Prop({ type: Boolean }) readonly hasNextStep?: boolean
  @Prop({ type: Boolean }) readonly isShownFloatingActionButton?: boolean;
  @Prop({ type: Boolean }) readonly skipValidation?: boolean
  @Prop({ type: String }) readonly justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between' = 'start'
  @Prop({ type: String }) readonly align?: 'top' | 'middle' | 'bottom' = 'top'
  @Prop({ type: String }) readonly submitButtonRef?: string
  @Prop({ type: Boolean, default: false }) readonly isHideSubmitButton?: boolean

  formErrors: Record<string, string> = {}
  customHTML = ''

  buttonsArray: Array<IFormButton> = [
    {
      id: 'FormBuilder_Submit',
      nativeType: 'submit',
      label: 'OK',
      hasNextStep: this.hasNextStep,
      isShowArrow: this.isShowArrow,
      type: '',
      inputType: ECustomButtonFormBuilderType.BUTTON,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => { },
    },
  ];

  get combinedButtonsArray() {
    if (!this.passedButtons || this.passedButtons.length === 0) {
      return this.buttonsArray
    }
    return [
      ...this.buttonsArray,
      ...this.passedButtons,
    ]
  }

  get isValidated() {
    return Object.values(this.formErrors).reduce((acc, curValue) => acc && curValue === '', true)
  }

  cbTasks: IPromiseHandlers[] = []

  scanBCKeys: string[] = []
  created() {
    this.resetError()

    this.scanBCKeys = Object.keys(this.formSchema).filter((key: string) =>
      this.isBarcodeScanFrame(this.formSchema[key].fileKind)
    )
  }

  resetError() {
    if (this.formModel) {
      Object.keys(this.formModel).forEach((f: string) => (this.formErrors[f] = ''), this)
    }
  }

  onUploaded(uploadedResponse: { url: string }) {
    if (uploadedResponse?.url && !isEmpty(this.cbTasks)) {
      this.cbTasks[this.cbTasks.length - 1].resolve()
      if (uploadedResponse.url !== 'none') this.clearUploadError()
    }
  }

  clearUploadError() {
    this.scanBCKeys.forEach((key) => this.clearError(key))
  }

  isBarcodeScanFrame(v?: string): boolean {
    return v === EFileKind.BARCODE_SCAN_FRAME
  }

  submit(e: Event) {
    e.preventDefault()
    this.validateAll()

    const isWaitUpload = !isEmpty(this.scanBCKeys)

    // Validate other fields if have
    this.$emit('validate')

    const submitFunc = () => {
      if (!this.skipValidation && !this.isValidated) return
      this.$emit('submit', this.formModel)
    }

    if (!isWaitUpload) submitFunc()
    else this.performUpload(() => submitFunc())
    this.scrollToError()
  }

  // validate all except scan-bc-frame field
  validateAll() {
    this.resetError()
    Object.keys(this.formSchema)
      .filter((key: string) => !this.scanBCKeys.includes(key))
      .forEach((key: string) => this.validate(key))
    return Object.values(this.formErrors).filter((err) => err).length === 0
  }

  validateBCScanFields() {
    Object.keys(this.formSchema)
      .filter((key: string) => this.scanBCKeys.includes(key))
      .forEach((key: string) => this.validate(key))
    return Object.values(this.formErrors).filter((err) => err).length === 0
  }

  performUpload(cb: Function) {
    const performCallback = () => {
      // run validate for individual scan-bc-frame fields
      this.validateBCScanFields()

      if (this.isValidated) cb()
    }
    if (this.$store.getters.scannedBarcodeBlob && !isEmpty(this.scanBCKeys)) {
      const scanBCFields = this.scanBCKeys.reduce((total, k: string) => ({ ...total, [k]: { isReady: true } }), {})
      this.$store.commit(UPDATE_SCANNED_UPLOAD_STATUS, scanBCFields)
      new Promise((resolve, reject) => this.cbTasks.push({ resolve, reject })).then(() => performCallback())
    } else {
      performCallback()
    }
  }

  validate(key: string) {
    if (this.skipValidation) return true

    const fieldSchema = this.formSchema[key]

    // Require validation
    if (fieldSchema?.isMandatory && isEmpty(this.formModel[key])) {
      this.formErrors[key] = this.$t('Please enter', { field: fieldSchema.label })
      return false
    }

    // Min length validation
    if (fieldSchema?.minLength) {
      const val = this.formModel[key] as string
      if (val?.length < fieldSchema.minLength) {
        this.formErrors[key] = this.$t('MinLen limit', { len: fieldSchema.minLength })
        return false
      }
    }

    // Min length validation
    if (fieldSchema?.maxLength) {
      const val = this.formModel[key] as string
      if (val?.length > fieldSchema.maxLength) {
        this.formErrors[key] = this.$t('MaxLen limit', { len: fieldSchema.maxLength })
        return false
      }
    }

    if (!isEmpty(this.formModel[key]) || fieldSchema.isMandatory) {
      if (fieldSchema?.fieldType === ECustomFieldType.EMAIL) {
        if (!validateEmail(this.formModel[key] as string)) {
          this.formErrors[key] = this.$t('email_format_incorrect')
          return false
        }
      }

      if (fieldSchema?.fieldType === ECustomFieldType.PHONE_NUMBER) {
        const phoneNumber = parsePhoneNumber(this.formModel[key] as string, fieldSchema.countryCode as CountryCode)
        if (phoneNumber && !phoneNumber.isValid()) {
          this.formErrors[key] = this.$t('phone_format_incorrect')
          return false
        }
      }

      if (fieldSchema?.fieldType === ECustomFieldType.GHG_EMISSION) {
        const ghgEmissionInput = this.formModel[key] as GhgEmissionInput
        const calculationType = fieldSchema.calculationType as GhgEmissionCalculationType
        let errorMessage = ''
        let isValid = true
        if (!ghgEmissionInput.transportType) {
          errorMessage += this.$t('ghgEmission.error.invalidTransportType')
          isValid = false
        }
        switch (calculationType) {
          case 'tonkilo':
            if (ghgEmissionInput.weight === undefined || ghgEmissionInput.weight <= 0) {
              errorMessage += this.$t('ghgEmission.error.invalidWeight')
              isValid = false
            }
            if (ghgEmissionInput.distance === undefined || ghgEmissionInput.distance <= 0) {
              errorMessage += this.$t('ghgEmission.error.invalidDistance')
              isValid = false
            }
            break
          case 'modifiedTonkilo':
            if (ghgEmissionInput.loadRate === undefined || ghgEmissionInput.loadRate <= 0) {
              errorMessage += this.$t('ghgEmission.error.invalidLoadRate')
              isValid = false
            }
            if (ghgEmissionInput.maxWeightCapacity === undefined || ghgEmissionInput.maxWeightCapacity <= 0) {
              errorMessage += this.$t('ghgEmission.error.invalidMaxWeightCapacity')
              isValid = false
            }
            if (ghgEmissionInput.distance === undefined || ghgEmissionInput.distance <= 0) {
              errorMessage += this.$t('ghgEmission.error.invalidDistance')
              isValid = false
            }
            break
          case 'fuelEfficiency':
            if (ghgEmissionInput.distance === undefined || ghgEmissionInput.distance <= 0) {
              errorMessage += this.$t('ghgEmission.error.invalidDistance')
              isValid = false
            }
            if (!ghgEmissionInput.vehicleUsageType) {
              errorMessage += this.$t('ghgEmission.error.invalidVehicleUsageType')
              isValid = false
            }
            break
          case 'fuel':
            if (ghgEmissionInput.fuelConsumption !== undefined && ghgEmissionInput.fuelConsumption <= 0) {
              errorMessage += this.$t('ghgEmission.error.invalidFuelConsumption')
              isValid = false
            }
            break
        }
        this.formErrors[key] = errorMessage
        return isValid
      }
    }

    this.clearError(key)
    return true
  }

  scrollToError() {
    try {
      this.$nextTick(() => {
        const errorElements = document.querySelectorAll('.error-msg')
        const firstVisibleError = Array.from(errorElements).find(element => {
          return window.getComputedStyle(element).display !== 'none'
        })
        if (firstVisibleError) {
          firstVisibleError.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    } catch (error) {
    }
  }

  clearError(key: string) {
    this.formErrors[key] = ''
  }
}
