import { BARCODE_SCAN } from '@/utils/constants'
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType, Result, ResultPoint } from '@zxing/library'
import store from '@/store'
import {
  Quadrilateral,
  Point,
  Camera,
  FrameSourceState,
  CameraSwitchControl,
  DataCaptureView,
  DataCaptureContext,
  configure,
  Feedback,
  Vibration,
  FrameData,
  Brush,
  Color,
} from 'scandit-web-datacapture-core'
import {
  Symbology,
  BarcodeCaptureOverlay,
  Barcode,
  BarcodeCapture,
  SymbologySettings,
  BarcodeCaptureSettings,
  BarcodeCaptureSession,
  BarcodeCaptureOverlayStyle,
  barcodeCaptureLoader,
  BarcodeCaptureFeedback,
} from 'scandit-web-datacapture-barcode'

const videoObjectFit = 'contain'

// Create the code location canvas element
const codeLocationCanvasElement = document.createElement('canvas')
codeLocationCanvasElement.classList.add('code-location-canvas')

const codeLocationCanvasContext = codeLocationCanvasElement.getContext('2d')

codeLocationCanvasElement.style.objectFit = codeLocationCanvasElement.dataset.objectFit = videoObjectFit

// -------------Scandit symbologies---------------------
export const default1DScanditDataCaptureFormat = [
  Symbology.Codabar,
  Symbology.EAN8,
  Symbology.Code128,
  Symbology.Code39,
  Symbology.EAN13UPCA,
  Symbology.UPCE,
  Symbology.InterleavedTwoOfFive,
]

export const default2DScanditDataCaptureFormat = [Symbology.QR, Symbology.MicroQR]

// -------------Xzing symbologies------------------------
export const default2DFormat = [BarcodeFormat.QR_CODE]

export const default1DFormat = [
  BarcodeFormat.CODABAR,
  BarcodeFormat.EAN_8,
  BarcodeFormat.EAN_13,
  BarcodeFormat.CODE_128,
  BarcodeFormat.CODE_39,
  BarcodeFormat.UPC_A,
  BarcodeFormat.UPC_E,
  BarcodeFormat.ITF,
]
// ------------------------------------------------------
export const defaultScanditDataCaptureFormat = [
  ...default1DScanditDataCaptureFormat,
  ...default2DScanditDataCaptureFormat,
]

export const defaultFormat = [...default1DFormat, ...default2DFormat]

function drawCodeLocation(location: Quadrilateral) {
  if (!codeLocationCanvasContext) return

  codeLocationCanvasContext.beginPath()
  codeLocationCanvasContext.moveTo(location.topLeft.x, location.topLeft.y)
  codeLocationCanvasContext.lineTo(location.topRight.x, location.topRight.y)
  codeLocationCanvasContext.lineTo(location.bottomRight.x, location.bottomRight.y)
  codeLocationCanvasContext.lineTo(location.bottomLeft.x, location.bottomLeft.y)
  codeLocationCanvasContext.closePath()
  codeLocationCanvasContext.lineWidth = 3
  codeLocationCanvasContext.strokeStyle = 'rgba(46, 192, 204, 0.6)'
  codeLocationCanvasContext.stroke()
  codeLocationCanvasContext.fillStyle = 'rgba(46, 192, 204, 0.3)'
  codeLocationCanvasContext.fill()

  setTimeout(() => {
    codeLocationCanvasContext.clearRect(
      0,
      0,
      codeLocationCanvasContext.canvas.width,
      codeLocationCanvasContext.canvas.height
    )
  }, BARCODE_SCAN.timeBetweenScansMillis || 500)
}

function getLocationFrom2Points(result: Result) {
  const location = {
    topLeft: {
      x: result.getResultPoints()[0].getX(),
      y: result.getResultPoints()[0].getY(),
    } as Point,
    topRight: {
      x: result.getResultPoints()[1].getX(),
      y: result.getResultPoints()[1].getY(),
    } as Point,
    bottomRight: {
      x: result.getResultPoints()[1].getX(),
      y: result.getResultPoints()[1].getY(),
    } as Point,
    bottomLeft: {
      x: result.getResultPoints()[0].getX(),
      y: result.getResultPoints()[0].getY(),
    } as Point,
  }
  return location
}

// Function to calculate the bounding box with dynamic padding
function calculateBoundingBox(points: ResultPoint[]) {
  // Find the extreme points
  const minX = Math.min(...points.map((point) => point.getX()))
  const minY = Math.min(...points.map((point) => point.getY()))
  const maxX = Math.max(...points.map((point) => point.getX()))
  const maxY = Math.max(...points.map((point) => point.getY()))

  // Calculate the size of the rectangle
  const width = maxX - minX
  const height = maxY - minY

  // Calculate the area of the rectangle
  const area = width * height

  // Calculate dynamic padding based on the area of the rectangle
  const paddingFactor = 0.0025 // Adjust as needed
  const dynamicPaddingX = area * paddingFactor
  const dynamicPaddingY = area * paddingFactor

  // Apply dynamic padding
  const paddedMinX = minX - dynamicPaddingX
  const paddedMinY = minY - dynamicPaddingY
  const paddedMaxX = maxX + dynamicPaddingX
  const paddedMaxY = maxY + dynamicPaddingY

  return {
    topLeft: { x: paddedMinX, y: paddedMinY },
    topRight: { x: paddedMaxX, y: paddedMinY },
    bottomRight: { x: paddedMaxX, y: paddedMaxY },
    bottomLeft: { x: paddedMinX, y: paddedMaxY },
  }
}

function getLastPoint(a: number, b: number, c: number) {
  const getVectorAB = b - a
  const d = c - getVectorAB
  return d
}

function getLocationFrom3Points(result: Result) {
  const location = {
    topLeft: {
      x: result.getResultPoints()[0].getX(),
      y: result.getResultPoints()[0].getY(),
    } as Point,
    topRight: {
      x: result.getResultPoints()[1].getX(),
      y: result.getResultPoints()[1].getY(),
    } as Point,
    bottomRight: {
      x: result.getResultPoints()[2].getX(),
      y: result.getResultPoints()[2].getY(),
    } as Point,
    bottomLeft: {
      x: getLastPoint(
        result.getResultPoints()[0].getX(),
        result.getResultPoints()[1].getX(),
        result.getResultPoints()[2].getX()
      ),
      y: getLastPoint(
        result.getResultPoints()[0].getY(),
        result.getResultPoints()[1].getY(),
        result.getResultPoints()[2].getY()
      ),
    } as Point,
  }
  return location
}

function getLocationFromResult(result: Result) {
  if (result.getResultPoints().length > 2) {
    // Readme: This is should changed to use Bounding box instead
    return getLocationFrom3Points(result)

    // return calculateBoundingBox(result.getResultPoints())
  }

  return getLocationFrom2Points(result)
}

export const defaultBarcodeReader = async (
  readerCallBack: Function,
  videoHTMLContainer: HTMLElement,
  stream: MediaStream,
  isDrawingOverlay: boolean,
  BarcodeTypesSupport: BarcodeFormat[]
) => {
  if (!videoHTMLContainer) return

  videoHTMLContainer.appendChild(codeLocationCanvasElement)
  const videoElement = videoHTMLContainer.getElementsByTagName('video')[0]
  videoElement.style.objectFit = videoElement.dataset.objectFit = videoObjectFit

  // Set canvas size to match the video stream dimensions
  videoElement.addEventListener('loadedmetadata', () => {
    // Adjust the canvas size to match the visible area of the video
    codeLocationCanvasElement.width = videoElement.videoWidth
    codeLocationCanvasElement.height = videoElement.videoHeight
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hints: Map<DecodeHintType, any> = new Map()
  hints.set(DecodeHintType.POSSIBLE_FORMATS, BarcodeTypesSupport)
  const codeReader = new BrowserMultiFormatReader(hints, BARCODE_SCAN.timeBetweenScansMillis as number)

  if (stream) {
    await codeReader
      .decodeFromStream(stream as MediaStream, videoElement, (result: Result) => {
        const handleResults = {
          ...result,
          text: result?.getText()?.trim(),
        }
        // Passing null to tell scan are actively in scanning process
        readerCallBack(handleResults?.text ? handleResults : null, codeReader)
        if (result) {
          try {
            if (isDrawingOverlay) {
              // Draw new code locations
              const boundingBox = getLocationFromResult(result)
              drawCodeLocation(boundingBox as Quadrilateral)
            }
          } catch (error) {
            console.log(error)
          }
        }
      })
      .catch((err) => {
        console.error('decode', err)
      })
  }
}

export const initScanditConfigure = async () => {
  await configure({
    licenseKey: store.getters.barcodeReaderLicenseKey || process.env.VUE_APP_SCANDIT_TOKEN,
    libraryLocation: new URL(
      'https://unpkg.com/browse/scandit-web-datacapture-barcode@6.23.0/build/engine/',
      document.baseURI
    ).toString(),
    moduleLoaders: [barcodeCaptureLoader({ highEndBlurryRecognition: false })],
  })
}

export const scanditDataCapture = async (
  scanditResultCallback: Function,
  videoHTMLElement: HTMLElement,
  isDrawingOverlay: boolean,
  contextCallBack: Function,
  scanditBarcodeTypesSupport: Symbology[] = defaultScanditDataCaptureFormat
) => {
  await initScanditConfigure()

  const context = await DataCaptureContext.create()
  // Try to use the world-facing (back) camera and set it as the frame source of the context. The camera is off by
  // default and must be turned on to start streaming frames to the data capture context for recognition.
  const camera: Camera = Camera.default
  const cameraSettings = BarcodeCapture.recommendedCameraSettings
  await camera.applySettings(cameraSettings)
  await context.setFrameSource(camera)

  // The barcode capturing process is configured through barcode capture settings,
  // they are then applied to the barcode capture instance that manages barcode recognition.
  const settings: BarcodeCaptureSettings = new BarcodeCaptureSettings()

  // The settings instance initially has all types of barcodes (symbologies) disabled. For the purpose of this
  // sample we enable a very generous set of symbologies. In your own app ensure that you only enable the
  // symbologies that your app requires as every additional enabled symbology has an impact on processing times.
  settings.enableSymbologies(
    scanditBarcodeTypesSupport.length === 0 ? defaultScanditDataCaptureFormat : scanditBarcodeTypesSupport
  )

  const codabarrSetting: SymbologySettings = settings.settingsForSymbology(Symbology.Codabar)
  codabarrSetting.activeSymbolCounts = [
    1,
    2,
    3,
    4,
    5,
    67,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
  ]

  settings.codeDuplicateFilter = BARCODE_SCAN.timeBetweenScansMillis || 500

  const barcodeCapture = await BarcodeCapture.forContext(context, settings)

  // Disable the barcode capture mode until the camera is accessed.
  await barcodeCapture.setEnabled(false)
  // To visualize the ongoing barcode capturing process on screen, set up a data capture view that renders the
  // camera preview. The view must be connected to the data capture context.
  const view = await DataCaptureView.forContext(context)

  // Connect the data capture view to the HTML element.
  view.connectToElement(videoHTMLElement)

  // Add a control to be able to switch cameras.
  view.addControl(new CameraSwitchControl())

  // Add a barcode capture overlay to the data capture view to render the location of captured barcodes on top of
  // the video preview. This is optional, but recommended for better visual feedback.
  const barcodeCaptureOverlay: BarcodeCaptureOverlay = await BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
    barcodeCapture,
    view,
    BarcodeCaptureOverlayStyle.Frame
  )
  barcodeCaptureOverlay.setBrush(new Brush(Color.fromRGBA(46, 192, 204, 0.3), Color.fromRGBA(46, 192, 204, 0.6), 3))

  // Switch the camera on to start streaming frames.
  // The camera is started asynchronously and will take some time to completely turn on.
  await camera.switchToDesiredState(FrameSourceState.On)
  await barcodeCapture.setEnabled(true)

  // Turn off feedback
  const feedback = BarcodeCaptureFeedback.default
  feedback.success = new Feedback(Vibration.defaultVibration, null)
  barcodeCapture.setFeedback(feedback)
  contextCallBack(context, barcodeCapture)

  // Register a listener to get informed whenever a new barcode got recognized.
  barcodeCapture.addListener({
    didScan: async (barcodeCaptureMode: BarcodeCapture, session: BarcodeCaptureSession, frameData: FrameData) => {
      // Hide the viewfinder.
      if (!isDrawingOverlay) {
        await barcodeCaptureOverlay.setViewfinder(null)
      }
      const barcode = { ...session.newlyRecognizedBarcodes[0], data: session.newlyRecognizedBarcodes[0]?.data?.trim() }
      scanditResultCallback(barcode)
    },
  })
}
