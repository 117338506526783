<template>
  <div class="display-in-center view relative" v-loading.fullscreen="loading">
    <div v-if="barcode">
      <div class="current-tracking-point-container">{{ currentTrackPointName }}</div>
      <BarcodeLabel :label="labelOfBC(barcode)" :subLabel="barcode.id" :type="barcode.barcodeType" />

      <div class="step-label">{{ $t('Create Tracking Data') }}</div>
      <div>
        <div class="section section-trackpoints">
          <div class="section-header">{{ $t('Next tracking point is') }}</div>
          <hr class="line-break" />

          <el-radio v-model="selectedTarget" v-for="trackpoint in targetChoices" class="next-trackingPoint"
            :key="trackpoint.key" :label="trackpoint.key">
            {{ trackpoint.name }}
          </el-radio>
        </div>
      </div>

      <div class="section-header shrink">
        {{ selectedStep }}
      </div>
      <hr class="line-break" />
      <FormBuilder :isShownFloatingActionButton="isFABButtonAvailable"
        :passedButtons="allowSkipTracking ? buttonsArrayInTrack : []" v-if="selectedTarget" :key="selectedTarget"
        :formSchema="selectedFormSchema" :formModel="formModel" @submit="confirmSubmitDialog"
        @reviewCalculation="reviewCalculation">
        <template v-slot:bottom-form>
          <div v-if="hasRemarks" style="margin-bottom: 20px">
            <div class="text-left my-4">{{ $t('remarks') }}</div>
            <div class="remarks-content" v-html="mergedHtml" />
          </div>
        </template>
      </FormBuilder>
    </div>
    <el-dialog :lock-scroll="true" width="90%" top="0" :model-value="showDialogAlert" :close-on-click-modal="false"
      :show-close="false" @closed="showDialogAlert = false">
      <div class="dialogMessage mb-3">{{ $t(dialogMessage) }}</div>
      <PreviewTracking v-if="isAddTrackingDialog && previewAddTrackingVisiblity" :barcode="barcode" :project="project"
        :trackingData="previewTrackingData" :fileListFormModel="fileListFormModel" />
      <template #footer>
        <span class="dialog-footer">
          <div>
            <el-button type="primary" @click="() => (isAddTrackingDialog ? submit(false) : submit(true))"
              class="full-w-button">
              {{ $t('OK') }}
            </el-button>
          </div>
          <div v-if="isAllowBulkUpdateAtCurrentTrackPoint || !isAddTrackingDialog">
            <el-button v-if="isKeyenceEnterpriseBrowser" type="primary" @click="
              onRouteTo(
                isAddTrackingDialog
                  ? 'bulk-tracking-with-keyence-handyterminal'
                  : 'bulk-skip-tracking-with-keyence-handyterminal'
              )
              " class="full-w-button">
              {{
                $t(
                  isAddTrackingDialog
                    ? 'ok_scan_barcode_to_track_multiple_barcodes'
                    : 'ok_scan_barcode_to_skip_multiple_barcodes_tracking'
                )
              }}
            </el-button>
            <el-button v-else type="primary"
              @click="onRouteTo(isAddTrackingDialog ? 'bulk-tracking' : 'bulk-skip-tracking')" class="full-w-button">
              {{
                $t(
                  isAddTrackingDialog
                    ? 'ok_scan_barcode_to_track_multiple_barcodes'
                    : 'ok_scan_barcode_to_skip_multiple_barcodes_tracking'
                )
              }}
            </el-button>
          </div>
          <div>
            <el-button @click="showDialogAlert = false" class="full-w-button">
              {{ $t('cancel') }}
            </el-button>
          </div>
        </span>
      </template>
    </el-dialog>
    <ReviewCalculationDialog v-model:isShow="isShowCalculationDialog" :content="calculationContent" />

  </div>
</template>
<script lang="ts">
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import ReviewCalculationDialog from '@/components/dialog/ReviewCalculationDialog.vue'
import FormBuilder, { IFormButton } from '@/components/FormBuilder.vue'
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'
import CustomFieldsMixin from '@/components/mixins/CustomFieldsMixin.vue'
import PreviewTracking from '@/components/PreviewTracking.vue'
import { BARCODE_ACTION_COMPLETE } from '@/store/actions'
import { compileMessage, reviewCalculation, tracking } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { flattenCustomFields, getDynamicLabel, isHideVisibilityConfig } from '@/utils/helpers'
import { isKeyenceEnterpriseBrowser } from '@/utils/keyenceHT/keyenceModules'
import mitt from '@/utils/mitt'
import isEmpty from 'lodash/isEmpty'
import { ECustomFieldType, ECustomButtonFormBuilderType } from 'smartbarcode-web-core/src/utils/enums'
import {
  IBarcode,
  IBarcodeDefinitionType,
  ICustomFieldData,
  IFieldSettings,
  ILocation,
  ITrackingRequestBody,
  TError,
  TFieldNotiSettings,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { ProvideReactive, Watch } from 'vue-property-decorator'

@Options({
  components: {
    BarcodeLabel,
    FormBuilder,
    PreviewTracking,
    ReviewCalculationDialog,
  },
  emits: ['submit'],
  name: 'TrackView',
})
export default class TrackView extends mixins(CustomFieldsMixin, BarcodeTypesMixin) {
  @ProvideReactive() isShowArrow = false
  imagesFromOCR: Record<string, string> = {}
  temporaryFieldsOCRImages: Record<string, string[]> = {}
  loading = false
  showDialogAlert = false
  errorDialogMessage = ''
  selectedTarget = ''
  targetChoices = [] as Array<{
    key: string
    name: string
    order?: number
  }>

  dialogMessage = ''
  location: ILocation | null = null
  isShowCalculationDialog = false
  calculationContent = ''
  isFloatingButtonVisible = true;
  observer: IntersectionObserver | null = null;
  targetRefButton = {} as HTMLElement | null

  isExpanded = false
  buttonsArrayInTrack: Array<IFormButton> = [
    {
      id: 'skipButton',
      nativeType: 'button',
      label: 'SKIP',
      type: 'danger',
      inputType: ECustomButtonFormBuilderType.BUTTON,
      onClick: () => {
        this.confirmSkipTrackingDialog()
      },
    },
  ];

  // Method to toggle expansion
  toggleExpanded() {
    this.isExpanded = !this.isExpanded
  }

  get currentBarcodeTypeInfo(): IBarcodeDefinitionType {
    return this.$store.state.project.details.barcodeTypes[this.barcode.barcodeType]
  }

  get isFABButtonAvailable() {
    return this.currentBarcodeTypeInfo?.isFloatingActionButton || false
  }

  get barcode() {
    return this.$store.state.barcode.barcode
  }

  get currentTrackPointKey() {
    return this.barcode?.currentTrackPointKey
  }

  get project() {
    return this.$store.state.project.details
  }

  get forms() {
    return this.project?.trackPoints[this.currentTrackPointKey]?.trackPointForms
  }

  get currentTrackPointName() {
    return this.project?.trackPoints[this.currentTrackPointKey]?.name
  }

  get customForm(): Record<string, IFieldSettings | TFieldNotiSettings> {
    return this.forms && this.forms[this.selectedTarget]
  }

  get isAuth(): boolean {
    return this.$store.getters.isAuth
  }

  get previewAddTrackingVisiblity(): boolean {
    const previewVisibility = this.currentBarcodeTypeInfo?.uiConfig?.previewAddTrackingVisiblity || ''
    return !isHideVisibilityConfig(previewVisibility, this.isAuth, false)
  }

  previewTrackingData = {}
  async loadPreviewTrackingData() {
    const calculationFields = await Object.entries(this.selectedFormSchema)
      .filter((entry) => (entry[1] as IFieldSettings).fieldType === ECustomFieldType.CALCULATION)
      .reduce(
        async (acc, entry) => ({
          ...acc,
          [entry[0]]: {
            fieldType: ECustomFieldType.CALCULATION,
            calculation: await this.getReviewCalculation(entry[0]),
          },
        }),
        {}
      )

    this.previewTrackingData = {
      ...this.generateFormRequest.trackingData,
      customFields: {
        ...this.generateFormRequest.trackingData.customFields,
        ...calculationFields,
      },
    }
  }

  async getReviewCalculation(calculationFieldKey: string) {
    this.appendOCRImage()
    const reviewPayload = {
      field: 'trackingData',
      customFieldKey: calculationFieldKey,
      startTrackPointKey: this.currentTrackPointKey,
      endTrackPointKey: this.selectedTarget,
      barcode: {
        ...this.barcode,
        trackingData: [this.generateFormRequest.trackingData],
      },
    }

    let content = ''
    try {
      content = (await reviewCalculation(reviewPayload)).data
    } catch (e) {
      errorHandler(e as TError)
    }
    return content
  }

  async reviewCalculation(calculationFieldKey: string) {
    this.loading = true
    this.calculationContent = await this.getReviewCalculation(calculationFieldKey)
    this.isShowCalculationDialog = true
    this.loading = false
  }

  labelOfBC(bc: IBarcode): string | undefined {
    const customValues = bc?.activationData || {}
    const customFields = this.currentBarcodeTypeInfo?.activationFields || {}
    const pathKey = this.currentBarcodeTypeInfo?.uiConfig.listViewTitleLabelField || ''
    const flattenCustomValues = flattenCustomFields(
      (customValues as unknown) as Record<string, Record<string, unknown>>
    ) as Record<string, ICustomFieldData>
    const flattenFields = flattenCustomFields(
      (customFields as unknown) as Record<string, Record<string, unknown>>
    ) as Record<string, IFieldSettings>
    return getDynamicLabel(flattenFields, flattenCustomValues, pathKey)
  }

  get selectedStep(): string {
    return this.$store.state.project.details.trackPoints[this.selectedTarget]?.name
  }

  get allowSkipTracking() {
    return this.forms[this.selectedTarget].allowSkipTracking
  }

  get hasRemarks(): boolean {
    return !isEmpty(this.customHtml)
  }

  get customHtml(): string {
    return (
      this.barcodeType?.trackPointRouteRemarks?.find(
        (remarks) => remarks.from === this.currentTrackPointKey && remarks.to === this.selectedTarget
      )?.remarks ?? ''
    )
  }

  mergedHtml = ''
  @Watch('customHtml')
  onUpdateCustomHtml() {
    if (!this.hasRemarks) return

    const cleanedHTML = this.customHtml.replace(/(\r\n|\n|\r)/gm, '')
    if (!isEmpty(cleanedHTML)) {
      compileMessage(this.$route.params.barcodeId, this.customHtml)
        .then((res) => (this.mergedHtml = res))
        .catch((err) => errorHandler(err))
    }
  }

  get barcodeType(): IBarcodeDefinitionType {
    return this.project?.barcodeTypes?.[this.barcode?.barcodeType]
  }

  get barcodeName(): string {
    return this.barcodeType.name || ''
  }

  get isAddTrackingDialog() {
    return this.dialogMode === 'add-tracking'
  }

  onFABConfirm(e: Event) {
    // eslint-disable-next-line no-unused-expressions
    this.$refs?.dimensionForm.submit(e)
  }

  dialogMode = 'add-tracking'
  async confirmSubmitDialog() {
    if (!this.showDialogAlert) {
      this.dialogMode = 'add-tracking'
      this.dialogMessage = 'confirm_add_track_data'
      await this.loadPreviewTrackingData()
      this.showDialogAlert = true
    }
  }

  confirmSkipTrackingDialog() {
    if (!this.showDialogAlert) {
      this.dialogMode = 'skip-add-tracking'
      this.dialogMessage = 'confirm_skip_track_data'
      this.showDialogAlert = true
    }
  }

  get isAllowBulkUpdateAtCurrentTrackPoint() {
    return this.currentBarcodeTypeInfo?.bulkUpdatableTrackPoints.includes(this.currentTrackPointKey)
  }

  onRouteTo(view: string, id?: string) {
    this.showDialogAlert = false
    const nextSelectedTrackingPoint: string =
      (this.targetChoices.find((val) => val.key === this.selectedTarget)?.name as string) || ''

    localStorage.tempRequestForMultipleBarcodeScan = JSON.stringify(this.generateFormRequest)

    this.$router.replace({
      name: view,
      params: {
        project: this.$store.getters.projectParam,
        barcodeId: id || this.barcode?.id,
        nextTrackName: nextSelectedTrackingPoint || '',
      },
    })
  }

  async submit(isSkip: boolean) {
    try {
      this.showDialogAlert = false
      this.loading = true
      const request = {
        ...this.generateFormRequest,
        selectedNextTrackpoint: this.nextSelectTrackingPointName,
        isSkip,
        isDryRun: false,
      }
      await tracking(request)
      this.imagesFromOCR = {}
      this.temporaryFieldsOCRImages = {}
      this.$store.dispatch(BARCODE_ACTION_COMPLETE, 'tracked')
      this.$router.push({
        name: 'detail',
        params: { barcodeId: this.barcode.id },
      })
    } catch (error) {
      const modelObject = {
        barcodeType: this.barcodeName,
        fTP1: this.currentTrackPointName,
        tTP2: this.targetChoices.find((val) => val.key === this.selectedTarget)?.name || '',
      }

      errorHandler(error as TError, modelObject)
    } finally {
      this.loading = false
    }
  }

  get generateFormRequest() {
    this.appendOCRImage()
    const generatedCustomDataForRequest = this.generateCustomDataForRequest
    const { location, ...other } = generatedCustomDataForRequest
    const requestBody: ITrackingRequestBody = {
      barcodeIds: [this.barcode.id],
      trackingData: {
        currentTrackPointKey: this.barcode.currentTrackPointKey,
        nextTrackPointKey: this.selectedTarget,
        location,
        customFields: {
          ...other,
        },
      },
    }

    return requestBody
  }

  @Watch('selectedTarget')
  updateCustomFormModel() {
    this.formModel = {}
    this.generateCustomFormModel(undefined)
  }

  appendOCRImage() {
    if (this.imagesFromOCR) {
      Object.keys(this.imagesFromOCR).forEach((key) => {
        this.formModel[key] = [...this.formModel?.[key], ...this.imagesFromOCR?.[key]]
      })
    }
  }

  mounted() {
    const project = this.$store.state.project.details

    if (this.forms) {
      this.targetChoices = Object.keys(this.forms).map((stepKey) => ({
        key: stepKey,
        name: project.trackPoints[stepKey].name,
        order: this.forms[stepKey].order,
      }))

      if (this.targetChoices.length > 0) {
        this.targetChoices.sort((a, b) => (a.order && b.order && a.order - b.order) || 0)
        this.selectedTarget = this.targetChoices[0].key
      }

      this.generateCustomFormModel(undefined)
    }

    mitt.on('update:trackInfo', (event) => {
      this.formModel = Object.assign(this.formModel, event)
    })

    mitt.on('update:fileList', (event) => {
      this.fileListFormModel = Object.assign(this.fileListFormModel, event)
    })

    mitt.on('update:imageOCR', (event) => this.saveOCRImage(event))
  }

  get isKeyenceEnterpriseBrowser() {
    return isKeyenceEnterpriseBrowser()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.current-tracking-point-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $border-color;
  padding: 8px 16px;
  margin-bottom: 20px;
  margin-top: 20px;
  background: #dadada;
  border-radius: 8px;
  line-height: 18px;
  text-align: left;
}

.remarks-content {
  @include label-box;
  text-align: left;
  border-radius: 5px !important;
  width: 100%;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.section {
  margin-top: 12px;
  margin-bottom: 20px;
}

hr.line-break {
  margin: 5px 0 15px 0;
}

.section-header {
  line-height: 2;
  text-align: left;

  &.shrink {
    margin-bottom: 0;
  }
}

.info {
  color: $main;
}

.next-trackingPoint {
  padding: 4px 0;
}

.section-trackpoints {
  .el-radio {
    margin-right: 0px;
  }
}
</style>
