
import { Options, Vue } from 'vue-class-component'
import IconArrow from '@/components/svg/IconArrow.vue'
import IconMore from '@/components/svg/IconMore.vue'
import { Prop } from 'vue-property-decorator'
import { IFormButton } from './FormBuilder.vue'
import FloatingButton from './FloatingButtonFormBuilder.vue'
@Options({
  components: { IconArrow, IconMore, FloatingButton },
})
export default class ButtonFormBuilder extends Vue {
  @Prop({ type: Boolean }) readonly isHideSubmitButton: boolean = false
  @Prop({ type: Boolean }) readonly hasNextStep: boolean = false
  @Prop({ type: Boolean }) readonly isShowArrow: boolean = false
  @Prop({ type: Array as () => IFormButton[] }) readonly buttons!: IFormButton[];
  @Prop({ type: Boolean }) readonly isShownFloatingActionButton?: boolean
  topButtonVisible = true;
  isExpanded = false;

  get isTopButtonVisible() {
    return this.topButtonVisible
  }

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.checkButtonVisibility()
  }

  handleScroll() {
    this.checkButtonVisibility()
  }

  handleClick(button: { onClick: () => void }) {
    if (button.onClick) {
      button.onClick()
    }
  }

  checkButtonVisibility() {
    const btn = this.buttons[0]
    const button = document.getElementById(btn.id || 'FormBuilder_Submit')
    if (button) {
      const rect = button.getBoundingClientRect()
      this.topButtonVisible = rect.top >= 0 && rect.bottom <= window.innerHeight
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded
  }
}
