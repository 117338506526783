
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import IconArrow from '@/components/svg/IconArrow.vue'
import { IFormButton } from './FormBuilder.vue'
@Options({
  components: { IconArrow },
  emits: ['toggle-expand'],
})
export default class FloatingButton extends Vue {
  @Prop({ type: Boolean }) readonly isExpanded!: boolean;
  @Prop({ type: Array as () => IFormButton[] }) readonly buttons!: IFormButton[];
  @Prop({ type: Function }) readonly handleClick!: (button: IFormButton) => void;

  get isRightBtnExpandable() {
    return this.buttons.length > 1
  }

  toggleExpand() {
    this.$emit('toggle-expand')
  }
}
