import { axiosInstance } from '@/utils/api'
import { EStockOperationType } from 'smartbarcode-web-core/src/utils/enums'

interface ILocationListParams {
  projectId: string
  barcodeId: string
  trackPointKey: string
}
export async function fetchLocationsByProjectIdAndTrackpoint(params: ILocationListParams) {
  const response = await axiosInstance.get('/location/available-locations', { params: { ...params } })
  return response?.data
}

interface ILocationTracking {
  locationId?: string
  type: EStockOperationType
  barcodeIds: string[]
}
export async function addLocationTracking(locationTrackingPayload: ILocationTracking) {
  const response = await axiosInstance.post('/location/location-tracking', locationTrackingPayload)
  return response?.data
}
