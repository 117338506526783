<template>
  <div class="floating-button">
    <template v-if="isRightBtnExpandable">
      <el-button class="expand-button" circle type="primary" @click="toggleExpand">
        <i v-if="!isExpanded" class="el-icon-more"></i>
        <i v-else class="el-icon-close"></i>
      </el-button>
      <transition name="fade">
        <div v-show="isExpanded" class="expanded-buttons">
          <template v-for="(button, index) in buttons" :key="index">
            <component :is="button.inputType || 'el-button'" :id="button.id" circle @click="handleClick(button)"
              :type="button.type || 'primary'" :native-type="button.nativeType"
              v-if="button.inputType !== 'el-radio-group'" class="submit-button custom-button">
              <IconArrow v-if="button.hasNextStep || button.isShowArrow" />
              <span v-else>{{ button.label }}</span>
            </component>

            <el-radio-group v-if="button.inputType === 'el-radio-group'" v-model="button.modelValue">
              <el-radio v-for="option in button.options" :key="option.value" :label="option.value"
                @change="handleClick(button)">
                {{ option.label }}
              </el-radio>
            </el-radio-group>
          </template>
        </div>
      </transition>
    </template>

    <template v-else>
      <template v-for="(button, index) in buttons" :key="index">
        <component :is="button.inputType || 'el-button'" :id="button.id" circle @click="handleClick(button)"
          :type="button.type || 'primary'" :native-type="button.nativeType" v-if="button.inputType !== 'el-radio-group'"
          class="submit-button">
          <IconArrow v-if="button.hasNextStep || button.isShowArrow" />
          <span v-else>{{ button.label }}</span>
        </component>

        <el-radio-group v-if="button.inputType === 'el-radio-group'" v-model="button.modelValue">
          <el-radio v-for="option in button.options" :key="option.value" :label="option.value"
            @change="handleClick(button)">
            {{ option.label }}
          </el-radio>
        </el-radio-group>
        <!-- <el-button :id="button.id" circle :type="button.type || 'primary'" native-type="submit" class="submit-button">
          <IconArrow v-if="button.hasNextStep || button.isShowArrow" />
          <span v-else>{{ button.label }}</span>
        </el-button> -->
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import IconArrow from '@/components/svg/IconArrow.vue'
import { IFormButton } from './FormBuilder.vue'
@Options({
  components: { IconArrow },
  emits: ['toggle-expand'],
})
export default class FloatingButton extends Vue {
  @Prop({ type: Boolean }) readonly isExpanded!: boolean;
  @Prop({ type: Array as () => IFormButton[] }) readonly buttons!: IFormButton[];
  @Prop({ type: Function }) readonly handleClick!: (button: IFormButton) => void;

  get isRightBtnExpandable() {
    return this.buttons.length > 1
  }

  toggleExpand() {
    this.$emit('toggle-expand')
  }
}
</script>

<style lang="scss" scoped>
.floating-button {
  position: fixed;
  border-color: white !important;
  z-index: 1000;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 10px;
}

.expand-button {
  margin-bottom: 10px;
}

.custom-button {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.expanded-buttons {
  display: flex;
  flex-direction: column-reverse;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.3s ease;
}

.expanded-button {
  color: white;
  border: none;
}
</style>
