<template>
  <span>
    <IconScan2D v-if="isEnabledBarcodeReadWithKeyenceHT" @click="startScanWithKeyenceHT" class="align-middle" width="34"
      height="34" viewBox="0 0 28 28" />
    <IconBarcodeReader v-else-if="hasBCR" @click="openBCR" class="align-middle mt-2" />
    <IconOCR v-if="hasOCR" @click="openOCR" class="align-middle mt-2" />
  </span>
  <ActionDialog v-model:isShowActionDialog="isShowDialog" :label="$t(ocrAction)" @update:confirm="confirmText($event)"
    :customBarcodeReaderType="scannerType" :ocrReaderType="ocrType" :actionType="ocrAction" :fieldName="fieldName" />
</template>
<script lang="ts">
import OCRComponent from '@/components/OCRComponent.vue'
import ScanComponent from '@/components/ScanComponent.vue'
import IconBarcodeReader from '@/components/svg/IconBarcodeReader.vue'
import IconOCR from '@/components/svg/IconOCR.vue'
import isEmpty from 'lodash/isEmpty'
import { EBCRType, ECameraReaderType, EOCRType } from 'smartbarcode-web-core/src/utils/enums/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import ActionDialog from './common/ActionDialog.vue'
import { isKeyenceEnterpriseBrowser, KeyenceModule, ScanResult } from '@/utils/keyenceHT/keyenceModules'
import IconScan2D from '@/components/svg/IconScan2D.vue'
import { openMessage } from '@/utils/helpers'
@Options({
  inheritAttrs: false,
  emits: ['update:modelValue', 'update:result'],
  components: {
    IconOCR,
    IconScan2D,
    IconBarcodeReader,
    OCRComponent,
    ScanComponent,
    ActionDialog,
  },
  name: 'InputReadersSuffix',
})
export default class InputReadersSuffix extends Vue {
  @PropSync('modelValue', { type: [String, Number] }) _modelValue?: string | number
  @Prop({ type: String }) readonly fieldName!: string
  @Prop({ type: String }) readonly ocrType!: string
  @Prop({ type: String }) readonly bcrType!: string

  isShowDialog = false
  ocrAction = ECameraReaderType.OCR

  get hasOCR(): boolean {
    return !isEmpty(this.ocrType) && this.ocrType !== EOCRType.UNKNOWN
  }

  get hasBCR(): boolean {
    return !isEmpty(this.bcrType) && this.bcrType !== EBCRType.UNKNOWN
  }

  get isOCRComponent(): boolean {
    return this.ocrAction === ECameraReaderType.OCR
  }

  get scannerType(): EBCRType {
    return this.bcrType as EBCRType
  }

  openBCR() {
    this.openCameraDialog(ECameraReaderType.BCR)
  }

  openOCR() {
    this.openCameraDialog(ECameraReaderType.OCR)
  }

  confirmText(value: string) {
    this.isShowDialog = false
    this._modelValue = value
    this.$emit('update:result', value)
  }

  openCameraDialog(action: ECameraReaderType) {
    this.isShowDialog = true
    this.ocrAction = action
  }

  onDialogClosing() {
    if (this.$refs?.ocrComponent) this.$refs.ocrComponent.vidOff()
  }

  get isEnabledBarcodeReadWithKeyenceHT() {
    return isKeyenceEnterpriseBrowser() && this.hasBCR && KeyenceModule && KeyenceModule.Scanner
  }

  async startScanWithKeyenceHT() {
    KeyenceModule.Scanner.clearReadCallback()
    KeyenceModule.Scanner.setReadCallback('keyenceHandyTerminalScanResultCallback')
    window.keyenceHandyTerminalScanResultCallback = (result: ScanResult) => {
      if (result.mDecodeResult !== 'SUCCESS') {
        return
      }
      const detectedText = result.mStringData?.trim() || ''
      this.confirmText(detectedText)
    }

    openMessage(
      this.$t('keyenceModule.messages.scanBarcode'),
      'info',
      4000)

    KeyenceModule.Scanner.startRead()
  }
}
</script>
