
import BarcodeLabelList from '@/components/BarcodeLabelList.vue'
import ScannerConfig from '@/components/keyenceHT/ScannerConfig.vue'
import IconScanQR from '@/components/svg/IconScanQR.vue'
import { FETCH_BARCODE, SAVE_SCANNED_BARCODES } from '@/store/actions'
import { getBarcode, getEstimateBarcodeLinked, linkingBarcodes } from '@/utils/api'
import { ELinkType } from '@/utils/enums'
import errorHandler from '@/utils/errorHandler'
import {
  getBarcodeIdFromQrCode,
  getProjectCode,
  isQrCode,
  openMessage,
  shouldHandleAsNewBarcode,
} from '@/utils/helpers'
import { KeyenceModule, ScanResult } from '@/utils/keyenceHT/keyenceModules'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IAPIErrorCode, IBarcode, IBarcodeDefinitionType, TError } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    IconScanQR,
    ScannerConfig: ScannerConfig,
    BarcodeLabelList,
  },
  name: 'BarcodeLinkScan',
})
export default class LinkScan extends Vue {
  prevScannedText = ''
  scannedBarcodeId = ''
  scannedBarcodes: IBarcode[] = []
  loading = false
  dialogVisible = false
  checkScannedBarcode = false
  estimateLinkedCount = 0

  async created() {
    this.loading = true
    await this.getBarcodeInfo()
    const { count } = await getEstimateBarcodeLinked(this.$route.params.barcodeId)
    this.estimateLinkedCount = count
    this.loading = false
  }

  async mounted() {
    const projectCode = getProjectCode(this.$route?.params?.project)

    if (KeyenceModule && KeyenceModule.Scanner) {
      KeyenceModule.Scanner.clearReadCallback()
      KeyenceModule.Scanner.setReadCallback('keyenceHandyTerminalScanResultCallback')
    }

    window.keyenceHandyTerminalScanResultCallback = async (result: ScanResult) => {
      if (result.mDecodeResult !== 'SUCCESS') {
        return
      }
      const scannedText = result.mStringData.trim()
      await this.handleScannedText(scannedText, projectCode)
      this.prevScannedText = scannedText
    }
  }

  async startScan() {
    KeyenceModule.Scanner.startRead()
  }

  get prevBarcodeId(): string {
    return this.$store.state.barcode?.barcode?.id
  }

  openErrorToast(message: string) {
    openMessage(message, 'error', 0)
  }

  async handleScannedText(plainText: string, projectCode: string) {
    if (!shouldHandleAsNewBarcode(plainText, this.prevScannedText, this.prevBarcodeId)) {
      return
    }
    const shouldTreatAsQrCode = isQrCode(plainText)
    const barcodeId = shouldTreatAsQrCode ? getBarcodeIdFromQrCode(plainText) : `projectCode=${projectCode}`
    const externalId = shouldTreatAsQrCode ? undefined : plainText
    if (!barcodeId) {
      return
    }

    try {
      const scannedBarcode: IBarcode = await getBarcode(barcodeId, externalId)
      if (this.scannedBarcodes.find((barcode) => barcode.id === scannedBarcode?.id)) {
        return
      }

      await this.validateBarcodeForLinking(scannedBarcode)

      const barcodeLinkingRequest = {
        barcodeId: this.barcode.id,
        targetBarcodeIds: [scannedBarcode.id],
        isDryRun: true,
      }
      await linkingBarcodes(ELinkType.LINK_TO, barcodeLinkingRequest)

      this.scannedBarcodeId = scannedBarcode.id
      const isAlreadyAdded =
        this.barcode?.id === scannedBarcode.id ||
        this.scannedBarcodes.find((barcode) => barcode.id === scannedBarcode.id)
      if (!isAlreadyAdded) {
        this.scannedBarcodes.push(scannedBarcode)
      }

      openMessage(this.$t('barcode detected'), 'success')
    } catch (error) {
      this.currentPlainText = ''
      if (error === 'cancel') {
        return
      }

      if (typeof error === 'object' && error?.hasOwnProperty('id')) {
        const message = this.$t(`errors.${(error as IAPIErrorCode)?.id?.pop()}`)
        this.openErrorToast(message)
        return
      }
      if (typeof error === 'string') {
        const modelObject = this.errorVariableTemplate()
        errorHandler(error as TError, modelObject)
        return
      }
      if (!isEmpty(error)) {
        const message = JSON.stringify(error)
        this.openErrorToast(message)
      }
    }
  }

  get barcodeName(): string {
    return this.barcodeType?.name || ''
  }

  get currentTrackPointName() {
    return this.project?.trackPoints[this.currentTrackPointKey]?.name
  }

  errorVariableTemplate() {
    return {
      barcodeType: this.barcodeName,
      fTP1: this.currentTrackPointName,
      tTP2: this.nextSelectTrackingPointName,
    }
  }

  async getBarcodeInfo() {
    if (this.$route.params?.barcodeId && this.$route.params?.project) {
      await this.$store.dispatch(FETCH_BARCODE, { id: this.$route.params?.barcodeId })
    }
  }

  get barcode() {
    return this.$store.state.barcode.barcode
  }

  onCheckScannedBarcode() {
    this.checkScannedBarcode = true
    this.dialogVisible = true
  }

  onCloseDialog() {
    this.dialogVisible = false
    this.checkScannedBarcode = false
  }

  barcodeTypeInfo(barcodeType: string): Record<string, IBarcodeDefinitionType> {
    return this.$store.state.project?.details?.barcodeTypes[barcodeType]
  }

  async validateBarcodeForLinking(barcode: IBarcode) {
    if (!barcode) {
      throw this.$t('barcode does not exist')
    }

    if (this.barcode.id === barcode.id) {
      throw this.$t('barcode_linking_by_itself_error')
    }

    const currentProjectId = this.$store.state.project.details.id
    const scannedBCProjectId = barcode.projectId
    if (currentProjectId !== scannedBCProjectId) {
      throw this.$t('scanned_barcode_not_same_project')
    }

    if (!barcode.isActivated) {
      throw this.$t('barcode not activated')
    }

    const bcTypeInfo = this.barcodeTypeInfo(barcode.barcodeType)
    if (!bcTypeInfo.allowToBeLinked) {
      throw this.$t('barcode_linking_not_allow')
    }
  }

  forward() {
    this.$store.dispatch(SAVE_SCANNED_BARCODES, this.scannedBarcodes)
    this.$router.push({
      name: 'scanned-barcode-list',
      params: {
        title: this.$t('linking.link_to_read_result'),
        operation: ELinkType.LINK_TO,
      },
    })
  }

  unmounted() {
    if (KeyenceModule && KeyenceModule.Scanner) {
      KeyenceModule.Scanner.clearReadCallback()
    }
  }
}
