
import { Options, mixins } from 'vue-class-component'
import IconInfo from '@/components/svg/IconInfo.vue'
import ScanArea from '@/components/svg/ScanArea.vue'
import { BrowserMultiFormatReader, Result } from '@zxing/library'
import BarcodeSymbologiesSelection from '@/components/BarcodeSymbologiesSelection.vue'
import { promptVideoPermission } from '@/utils/helpers'
import errorHandler from '@/utils/errorHandler'
import { TError } from 'smartbarcode-web-core/src/utils/types/index'
import BarcodeReaderMixin from '@/components/mixins/BarcodeReaderMixin.vue'
import { QRCODE_BARCODE_TYPE_REGEX } from '@/utils/constants'
import { UPDATE_SCANNED_RESULT } from '@/store/actions'
import { BarcodeCapture, Barcode } from 'scandit-web-datacapture-barcode'
@Options({
  components: {
    IconInfo,
    ScanArea,
    BarcodeSymbologiesSelection,
  },
  name: 'ScanView',
})
export default class ScanView extends mixins(BarcodeReaderMixin) {
  _barcodeDataCapture: BarcodeCapture | undefined
  codeReader = new BrowserMultiFormatReader()
  isReadyToScan = false
  video: HTMLVideoElement | null = null

  defaultScanReader(result: Result, codeReader: BrowserMultiFormatReader) {
    this.isReadyToScan = true
    this.codeReader = codeReader
    if (!result) return

    const temp: { [key: string]: unknown } = { ...result }
    this.handleScanResult((temp.text as string) || '')
    codeReader.reset()
  }

  async scanditDataResult(symbology: Barcode) {
    this.isReadyToScan = true
    if (!symbology) return

    this.handleScanResult(symbology.data || '')
  }

  async mounted() {
    try {
      this.isReadyToScan = false
      if (this.isScanditReaderProject) {
        await this.initScanditReader()
      } else {
        await promptVideoPermission()
        await this.initZxingReader()
      }
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.isReadyToScan = true
    }
  }

  async setupVideo() {
    if (!this.video) {
      this.video = document.getElementById('video') as HTMLVideoElement
    }

    await navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: 'environment',
          aspectRatio: 1,
          width: { ideal: 1000 },
          height: { ideal: 1000 },
          frameRate: { ideal: 30, max: 60 },
        },
      })
      .then((stream) => {
        // Setup the video stream
        if (this.video) {
          this.video.srcObject = stream
          const streamSetting = stream.getVideoTracks()[0].getSettings()
          // actual width & height of the camera video
          this.pictureWidth = streamSetting.width || this.pictureWidth
          this.pictureHeight = streamSetting.height || this.pictureHeight
        }
      })
      .catch((e) => {
        errorHandler(e.name === 'NotAllowedError' ? 'camera_access_not_allow' : e)
      })
  }

  snapshot() {
    try {
      const canvas = document.querySelector('canvas')
      const video = document.querySelector('video')
      if (video && canvas) {
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        const context = canvas?.getContext('2d')
        if (!context) return
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      }
      if (!video) return
      const context = canvas?.getContext('2d')
      if (!context) return
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      if (!canvas) return

      canvas.toBlob((blob) => this.$store.commit(UPDATE_SCANNED_RESULT, blob))
    } catch (error) { }
  }

  handleScanResult(plainText: string) {
    this.snapshot()

    const regexBarcodeId = QRCODE_BARCODE_TYPE_REGEX
    if (plainText.match(regexBarcodeId)) {
      const urlParts = plainText.split('/')
      const barcodeId = urlParts.pop()
      // Remove qrcode text from url
      urlParts.pop()
      const projectId = urlParts.pop()
      this.$router.push({
        name: 'detail',
        params: { barcodeId, project: projectId },
      })
    } else {
      const detectedText = plainText || ''
      const projectCode = this.$route?.params?.project.split('@')
      if (projectCode.length > 1) projectCode.pop()

      this.$router.push({
        name: 'detail',
        params: { barcodeId: `projectCode=${projectCode[0]}` },
        query: { externalId: detectedText },
      })
    }
  }
}
