
import BarcodeSymbologiesSelection from '@/components/BarcodeSymbologiesSelection.vue'
import BarcodeReaderMixin from '@/components/mixins/BarcodeReaderMixin.vue'
import ScanArea from '@/components/svg/ScanArea.vue'
import errorHandler from '@/utils/errorHandler'
import { promptVideoPermission } from '@/utils/helpers'
import { TError } from 'smartbarcode-web-core/src/utils/types/index'
import { BrowserMultiFormatReader, Result } from '@zxing/library'
import { Barcode, BarcodeCapture } from 'scandit-web-datacapture-barcode'
import { Options } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'

@Options({
  components: {
    ScanArea,
    BarcodeSymbologiesSelection,
  },
  emits: ['validate:locationId', 'update:isShow'],
  name: 'LocationScanDialog',
})
export default class LocationScanDialog extends BarcodeReaderMixin {
  @PropSync('isShow', { type: Boolean }) _isShow!: boolean

  closeLocationScanDialog() {
    this._isShow = false
  }

  _barcodeDataCapture: BarcodeCapture | undefined
  codeReader = new BrowserMultiFormatReader()
  isReadyToScan = false
  video: HTMLVideoElement | null = null

  defaultScanReader(result: Result, codeReader: BrowserMultiFormatReader) {
    this.isReadyToScan = true
    this.codeReader = codeReader
    if (!result) return

    const temp: { [key: string]: unknown } = { ...result }
    this.handleScanResult((temp.text as string) || '')
    // codeReader.reset()
  }

  async scanditDataResult(symbology: Barcode) {
    this.isReadyToScan = true
    if (!symbology) return

    this.handleScanResult(symbology.data || '')
  }

  @Watch('isShow')
  async initCamera() {
    if (!this._isShow) {
      this.video = document.getElementById('video') as HTMLVideoElement
      if (this.video?.srcObject) {
        ; (this.video.srcObject as MediaStream).getTracks().forEach((track) => {
          if (track.readyState === 'live') {
            track.enabled = false
            track.stop()
          }
        })
        if (this.codeReader) this.codeReader.reset()
      }
    } else {
      try {
        this.isReadyToScan = false
        this.$nextTick(async () => {
          if (this.isScanditReaderProject) {
            await this.initScanditReader()
          } else {
            await promptVideoPermission()
            await this.initZxingReader()
          }
        })
      } catch (error) {
        errorHandler(error as TError)
      } finally {
        this.isReadyToScan = true
      }
    }
  }

  async mounted() {
    this.initCamera()
  }

  async setupVideo() {
    if (!this.video) {
      this.video = document.getElementById('video') as HTMLVideoElement
    }

    await navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: 'environment',
          aspectRatio: 1,
          width: { ideal: 1000 },
          height: { ideal: 1000 },
          frameRate: { ideal: 30, max: 60 },
        },
      })
      .then((stream) => {
        // Setup the video stream
        if (this.video) {
          this.video.srcObject = stream
          const streamSetting = stream.getVideoTracks()[0].getSettings()
          // actual width & height of the camera video
          this.pictureWidth = streamSetting.width || this.pictureWidth
          this.pictureHeight = streamSetting.height || this.pictureHeight
        }
      })
      .catch((e) => errorHandler(e.name === 'NotAllowedError' ? 'camera_access_not_allow' : e))
  }

  handleScanResult(text: string) {
    this.$emit('validate:locationId', text)
  }
}
